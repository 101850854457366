import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PageServiceService } from "../page-service.service";
@Component({
  selector: "app-business-setting",
  templateUrl: "./business-setting.component.html",
  styleUrls: ["./business-setting.component.css"],
})
export class BusinessSettingComponent implements OnInit {
  //@ts-ignore
  generalForm: FormGroup;
  mixerForm!: FormGroup;
  addonForm!: FormGroup;
  isSubmitted: any = false;
  garnisherList: any = [];
  mixerList: any = [];
  categoriesForm!: FormGroup;
  subcategoriesForm!: FormGroup;
  addonsList: any = [];
  categoriesList: any = [];
  subCategoriesList: any = [];
  selectedItem: string = "Select Category";
  styleIceForm!: FormGroup;
  styleIceList: any = [];

  constructor(
    public pageService: PageServiceService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.getMixer();
    this.getCategories();
    this.getSubCategories();
    this.getStyleIce();

    this.generalForm = this.formBuilder.group({
      garnisher: ["", Validators.required],
    });
    this.mixerForm = this.formBuilder.group({
      mixers: ["", Validators.required],
      price: [""],
    });
    this.addonForm = this.formBuilder.group({
      addAddons: ["", Validators.required],
    });
    this.categoriesForm = this.formBuilder.group({
      categories: ["", Validators.required],
    });
    this.subcategoriesForm = this.formBuilder.group({
      subcategories: ["", Validators.required],
    });
    this.styleIceForm = this.formBuilder.group({
      styleice: ["", Validators.required],
    });
  }

  get f() {
    return this.generalForm.controls;
  }
  get mf() {
    return this.mixerForm?.controls;
  }

  get ff() {
    return this.addonForm.controls;
  }
  get catf() {
    return this.categoriesForm?.controls;
  }
  get subcatf() {
    return this.subcategoriesForm?.controls;
  }
  get si() {
    return this.styleIceForm.controls;
  }
  setSelectedItem(event: any) {
    const clickedElementId = event.target.value;
    console.log(clickedElementId);

    this.selectedItem = clickedElementId;
  }

  /* style & Ice */
  addStyleIce() {
    this.isSubmitted = true;
    if (this.styleIceForm.invalid) {
      return;
    }
    const body = {
      name: this.si.styleice.value,
      price: this.mf?.price.value ? this.mf?.price.value : 0,
    };
    this.pageService.addStyleIce(body).subscribe((res: any) => {
      if (res) {
        window.location.reload();
        this.isSubmitted = false;
        this.styleIceForm.reset();
      }
    });
  }
  getStyleIce() {
    this.pageService.getStyleIce().subscribe((res: any) => {
      if (res) {
        this.styleIceList = res;
      }
    });
  }
  deleteStyleIce(event: any) {
    const clickedElementId = event.target.getAttribute("id");
    this.pageService.deleteStyleIce(clickedElementId).subscribe((res: any) => {
      if (res) {
        console.log(res);
      }
      window.location.reload();
      this.pageService.loading.emit(false);
    });
  }
  addCategories() {
    this.isSubmitted = true;
    if (!this.categoriesForm || this.categoriesForm.invalid) {
      return;
    }
    const body = {
      name: this.catf?.categories.value,
      userId: 1,
      busId: 1,
      barId: 1,
    };
    this.pageService.addCategories(body).subscribe((res: any) => {
      if (res) {
        window.location.reload();
        this.isSubmitted = false;
        this.generalForm.reset();
      }
    });
  }
  getCategories() {
    this.pageService.getCategories().subscribe((res: any) => {
      if (res) {
        this.categoriesList = res;
      }
    });
  }
  deleteCategories(event: any) {
    const clickedElementId = event.target.getAttribute("id");
    this.pageService
      .deleteCategories(clickedElementId)
      .subscribe((res: any) => {
        if (res) {
          console.log(res);
        }
        window.location.reload();
        this.pageService.loading.emit(false);
      });
  }
  addSubCategories() {
    this.isSubmitted = true;
    if (!this.subcategoriesForm || this.subcategoriesForm.invalid) {
      return;
    }
    const body = {
      name: this.subcatf?.subcategories.value,
      cat_id: +this.selectedItem,
    };
    this.pageService.addSubCategories(body).subscribe((res: any) => {
      if (res) {
        window.location.reload();
        this.isSubmitted = false;
        this.generalForm.reset();
      }
    });
  }
  getSubCategories() {
    this.pageService.getSubCategories().subscribe((res: any) => {
      if (res) {
        this.subCategoriesList = res;
      }
    });
  }
  deleteSubCategories(event: any) {
    const clickedElementId = event.target.getAttribute("id");
    this.pageService
      .deleteSubCategories(clickedElementId)
      .subscribe((res: any) => {
        if (res) {
          console.log(res);
        }
        window.location.reload();
        this.pageService.loading.emit(false);
      });
  }

  addMixer() {
    this.isSubmitted = true;
    if (!this.mixerForm || this.mixerForm.invalid) {
      return;
    }
    const body = {
      name: this.mf?.mixers.value,
      price: this.mf?.price.value ? this.mf?.price.value : 0, //nai ani
      // type: "MIXERS",
    };
    this.pageService.addMixer(body).subscribe((res: any) => {
      if (res) {
        console.log(res);
        window.location.reload();
        this.isSubmitted = false;
        this.generalForm.reset();
      }
    });
  }
  getMixer() {
    this.pageService.getMixer().subscribe((res: any) => {
      if (res) {
        this.mixerList = res;
      }
    });
  }
  deleteMixer(event: any) {
    const clickedElementId = event.target.getAttribute("id");
    this.pageService.deleteMixer(clickedElementId).subscribe((res: any) => {
      if (res) {
        console.log(res);
      }
      window.location.reload();
      this.pageService.loading.emit(false);
    });
  }
}
