import { Component, OnInit } from "@angular/core";
import { PageServiceService } from "../page-service.service";
import { environment } from "src/environments/environment";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Roles } from "src/app/utils/roles.enum";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class DashboardComponent implements OnInit {
  usersList: any = [];
  userListData: Array<any> = [];
  baseUrl: string = environment.baseUrl;
  selectedItem: string = "Select Role";
  role: number | undefined;
  // @ts-ignore
  inviteForm: FormGroup;
  isSubmitted = false;
  errorMessage: string = "";
  revenue: any;
  managerList: any = [];
  editorList: any = [];
  manager: any;
  editor: any;
  managerStatus: any;

  constructor(
    public pageService: PageServiceService,
    private formBuilder: FormBuilder
  ) {}
  get formControls() {
    return this.inviteForm.controls;
  }
  ngOnInit(): void {
    this.inviteForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
    });
    // this.getUsersListByRole();
    this.getTodayCount();
    this.getManager();
    this.getEditor();
  }

  statusManager(event: any) {
    const clickedElementId = event.target.id;

    this.pageService.enableUser(clickedElementId).subscribe((res: any) => {
      if (res) {
        window.location.reload();
        this.managerStatus = res.status;
      }
    });
  }
  statusManagerDisable(event: any) {
    const clickedElementId = event.target.id;
    this.pageService.disableUser(clickedElementId).subscribe((res: any) => {
      if (res) {
        window.location.reload();
      }
    });
  }

  setSelectedItem(option: string) {
    this.selectedItem = option;
    this.role =
      option == "Manager"
        ? Roles.MANAGER
        : option == "Editor"
        ? Roles.EDITOR
        : 0;
  }
  getUsersListByRole() {
    this.pageService.loading.emit(true);
    this.pageService
      .getusersbyrole({ roles: [2, 3, 4, 5] })
      .subscribe((res: any) => {
        if (res.success) {
          this.usersList = res.data;

          this.pageService.loading.emit(false);
        }
      });
  }
  inviteSend() {
    this.isSubmitted = true;
    if (this.inviteForm.invalid) {
      return;
    }
    this.isSubmitted = false;
    this.pageService.loading.emit(true);
    const body = {
      email: this.formControls.email.value,
      role: this.role,
    };
    this.pageService.sentInvite(body).subscribe(
      (res: any) => {
        if (res) {
          this.isSubmitted = false;
          this.inviteForm.reset();
          window.location.reload();
          this.pageService.loading.emit(false);
        }
      },
      (error) => {
        this.pageService.loading.emit(false);
        this.errorMessage = error.error.message;
        // ? error.error.message
        // : "Select Role";
        // this.errorMessage = "Select Role";
      }
    );
  }
  getTodayCount() {
    this.pageService.getTodayCount().subscribe((res: any) => {
      if (res) {
        this.revenue = res;
      }
      this.pageService.loading.emit(false);
    });
  }
  resetPassLinkManager(event: any) {
    const clickedElementId = event.target.id;
    this.manager = this.managerList.find(
      (x: { id: any }) => x.id == clickedElementId
    );
    const body = {
      email: this.manager.email,
    };
    this.pageService.requestToken(body).subscribe((res: any) => {
      if (res) {
        console.log(res);
      }
    });
  }
  resetPassLinkEditor(event: any) {
    const clickedElementId = event.target.id;
    this.editor = this.editorList.find(
      (x: { id: any }) => x.id == clickedElementId
    );
    const body = {
      email: this.editor.email,
    };
    this.pageService.requestToken(body).subscribe((res: any) => {
      if (res) {
        console.log(res);
      }
    });
  }

  getManager() {
    this.pageService.getManager().subscribe((res: any) => {
      if (res) {
        this.managerList = res;
      }
      this.pageService.loading.emit(false);
    });
  }
  getEditor() {
    this.pageService.getEditor().subscribe((res: any) => {
      if (res) {
        this.editorList = res;
      }
      this.pageService.loading.emit(false);
    });
  }
}
