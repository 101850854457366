import { Component, OnInit } from "@angular/core";
import { PageServiceService } from "../page-service.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-help-request",
  templateUrl: "./help-request.component.html",
  styleUrls: ["./help-request.component.css"],
})
export class HelpRequestComponent implements OnInit {
  queriesList: any = [];
  baseUrl: string = environment.baseUrl;
  constructor(public pageService: PageServiceService) {}

  ngOnInit(): void {
    this.getqueryList();
  }
  getqueryList() {
    this.pageService.loading.emit(true);
    this.pageService.getqueryList().subscribe((res: any) => {
      if (res.success) {
        this.queriesList = res.data;
      }
      this.pageService.loading.emit(false);
    });
  }
}
