import { Component, OnInit } from "@angular/core";
import { PageServiceService } from "../page-service.service";
import { environment } from "src/environments/environment";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Roles } from "src/app/utils/roles.enum";
import { ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";

@Component({
  selector: "app-verify-users",
  templateUrl: "./verify-users.component.html",
  styleUrls: ["./verify-users.component.css"],
})
export class VerifyUsersComponent implements OnInit {
  userList: Array<any> = [];
  businessList: any = [];
  userListData: Array<any> = [];
  businessListData: any = [];
  // @ts-ignore
  inviteForm: FormGroup;
  baseUrl: string = environment.baseUrl;
  userText: any = "";
  businessText: any = "";
  isSubmitted = false;
  selectedItem: string = "Select Role";
  role: number | undefined;
  userIdData: any;
  errorMessage: string = "";
  showEnlarged = false;
  enlargedShow = false;
  enlargedImageUrl: string = "";
  enlargedViewStyle: any = {};
  toastShown: boolean = false;
  successMessage: string = "";

  constructor(
    public pageService: PageServiceService,
    private formBuilder: FormBuilder,
    private router: ActivatedRoute,
    private location: Location
  ) {
    this.pageService.loading.emit(true);
  }
  get formControls() {
    return this.inviteForm.controls;
  }

  ngOnInit(): void {
    this.router.queryParams.subscribe((params) => {
      this.successMessage = params["successMessage"] || "";
      if (this.successMessage) {
        this.location.replaceState("/verify-users");
        setTimeout(() => {
          this.successMessage = "";
        }, 3000);
      }
    });

    this.inviteForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
    });
    this.getusers();
    this.getBusiness();
  }

  setSelectedItem(option: string) {
    this.selectedItem = option;
    this.role =
      option == "Manager"
        ? Roles.MANAGER
        : option == "Editor"
        ? Roles.EDITOR
        : 0;
  }

  getusers() {
    this.pageService.getUnverifyUser().subscribe((res: any) => {
      if (res) {
        this.userList = res;
        this.userListData = res;
      }
    });
  }
  getBusiness() {
    this.pageService.getUnverifyBusiness().subscribe((res: any) => {
      if (res) {
        this.businessList = res;
        this.businessListData = res;
      }
      this.pageService.loading.emit(false);
    });
  }
  showEnlargedView(imageUrl: string): void {
    this.enlargedImageUrl = imageUrl;
    this.showEnlarged = true;
    this.enlargedViewStyle = {
      background: "rgba(0, 0, 0, 0.7)",
      position: "fixed",
      top: "0",
      left: "500px",
      right: "0",
      bottom: "0",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: "990",
      width: "1000px",
      height: "1000px",
    };
  }
  closeEnlargedView(): void {
    this.showEnlarged = false;
    this.enlargedViewStyle = {};
  }
}
