<div class="main-wrapper">
    <ng-container *ngIf="user !== null">
        <app-sidebar></app-sidebar>
    </ng-container>
    <div class="main-content">
        <ng-container *ngIf="user !== null">
            <app-header></app-header>
        </ng-container>
        <router-outlet></router-outlet>
    </div>

    <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }">
    </ngx-loading>
    <!-- 
    <app-login></app-login> -->
</div>