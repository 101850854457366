<!-- .........content......... -->
<div class="content">
  <div class="row mb-4">
    <div class="cards invite-card w-100">
      <div
        class="cards-header d-flex justify-content-between align-items-center"
      >
        <div class="d-flex align-items-center">
          <a routerLink="/help-center">
            <img src="../../../assets/img/left.png" alt="back" width="28px" />
          </a>
          <h3 class="cards-heading mx-4">Ticket No. {{ support_id }}</h3>
          <img
            src="../../../assets/img/star-fill.svg"
            class="mb-2"
            alt="star"
          />
        </div>
        <a
          class="{{ repliesList?.status ? 'status' : 'status-close' }} "
          href="javascript:void(0)"
          >{{ repliesList?.status ? "Open" : "Closed" }}</a
        >
      </div>
      <div class="border-btm">
        <div class="guest-row guest-row-mob border-bottom-0">
          <div class="d-flex">
            <div class="profle-pic me-3">
              <ng-container
                *ngIf="
                  repliesList?.user?.profile_url &&
                    repliesList?.user?.profile_url !== '';
                  else elseTemp1
                "
              >
                <img
                  src="{{ baseUrl }}{{ repliesList?.user?.profile_url }}"
                  alt="profile pic"
                  class="real-pic"
                />
              </ng-container>
              <ng-template #elseTemp1>
                <img
                  src="../../../assets/img/profile-pic.png"
                  alt="profile pic"
                />
              </ng-template>
            </div>
            <div class="profile-details">
              <h1 class="profile-name my-1">
                {{
                  repliesList?.user?.first_name
                    ? repliesList?.user?.first_name
                    : "N/A"
                }}
                {{
                  repliesList?.user?.last_name
                    ? repliesList?.user?.last_name
                    : "N/A"
                }}
              </h1>
              <p class="manager-email">
                {{
                  repliesList?.user?.email ? repliesList?.user?.email : "N/A"
                }}
              </p>
            </div>
          </div>
          <div
            class="tag {{
              repliesList?.user?.role === 3 ? 'guest-tag' : 'business-tag'
            }} mx-5"
          >
            {{ repliesList?.user?.role === 3 ? "Guest" : "Business" }}
          </div>
        </div>
        <p class="profile-message mb-2">{{ repliesList?.subject }}</p>
        <div class="d-flex align-items-center mb-3 ps-5 mt-3">
          <p class="date ps-4">
            {{ repliesList.doc | date : "MM/dd/yyyy : hh:mm a" }}
          </p>
        </div>
      </div>
      <ng-container *ngFor="let item of repliesList?.support_replies">
        <div class="border-btm">
          <div class="guest-row border-bottom-0">
            <div class="d-flex">
              <div class="profle-pic me-3">
                <ng-container *ngIf="item?.user?.role !== 1">
                  <ng-container
                    *ngIf="
                      repliesList?.user?.profile_url &&
                        repliesList?.user?.profile_url !== '';
                      else elseTemp1
                    "
                  >
                    <img
                      src="{{ baseUrl }}{{ repliesList?.user?.profile_url }}"
                      alt="managers"
                      class="real-pic"
                    />
                  </ng-container>
                  <ng-template #elseTemp1>
                    <img
                      src="../../../assets/img/profile-pic.png"
                      alt="managers"
                    />
                  </ng-template>
                </ng-container>
                <!-- admin image -->

                <ng-container *ngIf="item?.user?.role === 1">
                  <img
                    src="../../../assets/img/admin.png"
                    alt="admin pic"
                    class="chat-pic"
                  />
                </ng-container>
              </div>
              <div class="profile-details" *ngIf="item?.user?.role !== 1">
                <h1 class="profile-name my-1">
                  {{ item?.user?.first_name ? item?.user?.first_name : "N/A" }}
                  {{ item?.user?.last_name ? item?.user?.last_name : "N/A" }}
                </h1>
                <p class="manager-email">
                  {{ item?.user?.email ? item?.user?.email : "N/A" }}
                </p>
              </div>
              <div class="profile-details" *ngIf="item?.user?.role === 1">
                <h1 class="profile-name my-1">You</h1>
                <p class="manager-email">
                  {{ item?.user?.email ? item?.user?.email : "N/A" }}
                </p>
              </div>
            </div>
            <div
              *ngIf="item?.user?.role !== 1"
              class="tag {{
                item?.user?.role === 3 ? 'guest-tag' : 'business-tag'
              }} mx-5"
            >
              {{ item?.user?.role === 3 ? "Guest" : "Business" }}
            </div>
          </div>
          <p class="profile-message mb-2">{{ item?.message }}</p>
          <div class="d-flex align-items-center mb-3 ps-5 mt-3">
            <p class="date ps-4">
              {{ item?.doc | date : "MM/dd/yyyy : hh:mm a" }}
            </p>
          </div>
        </div>
      </ng-container>
      <div>
        <div class="guest-row align-items-start border-bottom-0">
          <div class="profle-pic me-3">
            <img
              src="../../../assets/img/admin.png"
              alt="profile pic"
              class="chat-pic"
            />
          </div>
          <textarea
            class="form-control text-area mb-4"
            id="comment"
            name="text"
            [(ngModel)]="replyText"
            placeholder="Reply Here..."
          ></textarea>
        </div>
        <button type="button" class="btn invite-btn" (click)="sendReply()">
          Send Reply
        </button>
      </div>
    </div>
  </div>
</div>
