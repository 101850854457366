<div class="main-content main-content-login">
  <div class="d-flex">
    <div class="login-lt-wrapper">
      <div class="logo-wrapper">
        <img src="../../../assets/img/login-logo.svg" alt="logo" class="logo" />
      </div>
      <p class="logo-text">The Drink App: Managing Portal</p>
    </div>
    <div class="login-rt-wrapper">
      <div class="form-wrapper">
        <h2 class="form-heading">Forgot Password</h2>
        <div class="mt-3">
          <form [formGroup]="emailForm" (ngSubmit)="requestedToken()">
            <div class="mb-3">
              <label for="emailInput" class="form-label input-label"
                >Enter Email</label
              >
              <input
                type="text"
                class="form-control input-area"
                formControlName="email"
                id="emailInput"
                placeholder="Please enter your email address"
                required
              />
              <div
                *ngIf="isSubmitted && formControls.email.errors"
                class="form-error-block"
              >
                <div
                  *ngIf="formControls.email.errors.required"
                  class="error-msg"
                >
                  Email is required
                </div>
                <div *ngIf="formControls.email.errors.email" class="error-msg">
                  Email is invalid!
                </div>
              </div>
            </div>

            <button type="submit" class="btn btn-primary login-btn">
              Next
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
