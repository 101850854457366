import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { environment } from "src/environments/environment";
import { PageServiceService } from "../page-service.service";

@Component({
  selector: "app-view-user",
  templateUrl: "./view-user.component.html",
  styleUrls: ["./view-user.component.css"],
})
export class ViewUserComponent implements OnInit {
  baseUrl: string = environment.baseUrl;
  guestId: any;
  guestData: any = [];
  guestIdData: any = [];
  showEnlarged = false;
  enlargedShow = false;
  enlargedImageUrl: string = "";
  enlargedViewStyle: any = {};
  constructor(
    private activatedRoute: ActivatedRoute,
    public pageService: PageServiceService
  ) {}

  ngOnInit(): void {
    this.getGuestUserData();
    setTimeout(() => {
      this.fetchData();
    }, 2000);
  }
  fetchData() {
    this.guestId = this.activatedRoute.snapshot.paramMap.get("id");
    this.guestIdData = this.guestData.find(
      (x: { id: any }) => x.id == this.guestId
    );
  }
  getGuestUserData() {
    this.pageService.getusers().subscribe((res: any) => {
      if (res) {
        this.guestData = res;
      }
      this.pageService.loading.emit(false);
    });
  }
  showEnlargedView(imageUrl: string): void {
    this.enlargedImageUrl = imageUrl;
    this.showEnlarged = true;
    this.enlargedViewStyle = {
      background: "rgba(0, 0, 0, 0.7)",
      position: "fixed",
      top: "0",
      left: "500px",
      right: "0",
      bottom: "0",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: "990",
      width: "1000px",
      height: "1000px",
    };
  }
  closeEnlargedView(): void {
    this.showEnlarged = false;
    this.enlargedViewStyle = {};
  }
}
