import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { environment } from "src/environments/environment";
import { PageServiceService } from "../page-service.service";

@Component({
  selector: "app-view-editors",
  templateUrl: "./view-editors.component.html",
  styleUrls: ["./view-editors.component.css"],
})
export class ViewEditorsComponent implements OnInit {
  baseUrl: string = environment.baseUrl;
  managerId: any;
  managerList: any = [];
  managerIdData: any = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    public pageService: PageServiceService
  ) {}

  ngOnInit(): void {
    this.getEditor();
    setTimeout(() => {
      this.fetchData();
    }, 1000);
  }
  fetchData() {
    this.managerId = this.activatedRoute.snapshot.paramMap.get("id");
    this.managerIdData = this.managerList.find(
      (x: { id: any }) => x.id == this.managerId
    );
  }
  getEditor() {
    this.pageService.getEditor().subscribe((res: any) => {
      if (res) {
        this.managerList = res;
      }
      this.pageService.loading.emit(false);
    });
  }
}
