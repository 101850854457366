import { Component, OnInit } from "@angular/core";
import { PageServiceService } from "../page-service.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-edit-business-user",
  templateUrl: "./edit-business-user.component.html",
  styleUrls: ["./edit-business-user.component.css"],
})
export class EditBusinessUserComponent implements OnInit {
  //@ts-ignore
  generalForm: FormGroup;
  isSubmitted: any = false;
  baseUrl: string = environment.baseUrl;
  businessId: any;
  businessData: any = [];
  businessIdImg: any;
  businessIdData: any;
  showEnlarged = false;
  enlargedShow = false;
  enlargedImageUrl: string = "";
  enlargedViewStyle: any = {};

  constructor(
    public pageService: PageServiceService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.getBusinessUserData();
    setTimeout(() => {
      this.fetchData();
    }, 2000);
    this.generalForm = this.formBuilder.group({
      businessname: ["", Validators.required],
      fname: ["", Validators.required],
      lname: ["", Validators.required],
      email: ["", Validators.required],
      address: ["", Validators.required],
    });
  }
  get f() {
    return this.generalForm.controls;
  }
  fetchData() {
    this.businessId = this.activatedRoute.snapshot.paramMap.get("id");
    this.businessIdData = this.businessData.find(
      (x: { id: any }) => x.id == this.businessId
    );
    this.generalForm.get("businessname")?.setValue(this.businessIdData.name);
    this.generalForm
      .get("fname")
      ?.setValue(this.businessIdData.user.first_name);
    this.generalForm.get("lname")?.setValue(this.businessIdData.user.last_name);
    this.generalForm.get("email")?.setValue(this.businessIdData.user.email);
    this.generalForm.get("address")?.setValue(this.businessIdData.address);
    this.generalForm.get("fname")?.disable();
    this.generalForm.get("lname")?.disable();
    this.generalForm.get("email")?.disable();
    this.businessIdImg = this.businessData.find(
      (x: { id: any }) => x.id == this.businessId
    );
  }
  getBusinessUserData() {
    this.pageService.getBusiness().subscribe((res: any) => {
      if (res) {
        this.businessData = res.businesses;
      }
      this.pageService.loading.emit(false);
    });
  }
  updateBusUserData() {
    this.isSubmitted = true;

    this.pageService.loading.emit(true);
    const body = {
      id: +this.businessIdData.id,
      user_name: this.f.businessname.value,
      name: this.f.businessname.value,
      first_name: this.f.fname.value,
      last_name: this.f.lname.value,
      email: this.f.email.value,
      address1: this.f.address.value,
      address: this.f.address.value,
      country_code: this.businessIdData.country_code
        ? this.businessIdData.country_code
        : "",
      mobile_no: this.businessIdData.mobile_no
        ? this.businessIdData.mobile_no
        : "",
      country: this.businessIdData.country ? this.businessIdData.country : "",
      city: this.businessIdData.city ? this.businessIdData.city : "",
      state: this.businessIdData.state ? this.businessIdData.state : "",
      zipcode: this.businessIdData.zipcode ? this.businessIdData.zipcode : "",
      doc_pic_front: this.businessIdData.doc_pic_front
        ? this.businessIdData.doc_pic_front
        : "",
      doc_pic_back: this.businessIdData.doc_pic_back
        ? this.businessIdData.doc_pic_back
        : "",
      profile_url: this.businessIdData.profile_url
        ? this.businessIdData.profile_url
        : "",
      doc_expires_on: this.businessIdData.doc_expires_on
        ? this.businessIdData.doc_expires_on
        : "1999-10-30T19:00:00.000Z",
      latitude: this.businessIdData.latitude,
      longitude: this.businessIdData.longitude,
      step: this.businessIdData.step,
    };
    this.pageService.updateBusinessUser(body).subscribe((res: any) => {
      if (res) {
        this.isSubmitted = false;
        window.location.replace("/users");
        this.generalForm.reset();
        this.pageService.loading.emit(false);
      }
    });
  }

  showEnlargedView(imageUrl: string): void {
    this.enlargedImageUrl = imageUrl;
    this.showEnlarged = true;
    this.enlargedViewStyle = {
      background: "rgba(0, 0, 0, 0.7)",
      position: "fixed",
      top: "0",
      left: "500px",
      right: "0",
      bottom: "0",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: "990",
      width: "1000px",
      height: "1000px",
    };
  }
  closeEnlargedView(): void {
    this.showEnlarged = false;
    this.enlargedViewStyle = {};
  }
}
