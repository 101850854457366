import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { PageServiceService } from "src/app/pages/page-service.service";
import { AuthServiceService } from "../auth-service.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  // @ts-ignore
  loginForm: FormGroup;
  isSubmitted = false;
  errorMessage: string = "";
  constructor(
    private formBuilder: FormBuilder,
    public router: Router,
    public authService: AuthServiceService,
    public pageService: PageServiceService
  ) {
    this.pageService.logout();
  }

  get formControls() {
    return this.loginForm.controls;
  }
  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", Validators.required],
    });
  }
  login() {
    this.isSubmitted = true;
    this.errorMessage = "";
    if (this.loginForm.invalid) {
      return;
    }
    this.pageService.loading.emit(true);
    let body = {
      email: this.formControls.email.value,
      password: this.formControls.password.value,
    };
    this.authService.login(body).subscribe(
      (res: any) => {
        if (res.success) {
          localStorage.setItem("authToken", res.body?.authToken);
          localStorage.setItem("user", JSON.stringify(res.body?.user));
          this.pageService.userSubject.next(res.body?.user);
          this.pageService.loading.emit(false);
          if (res.body?.user?.role == 1 || res.body?.user?.role == 6) {
            this.router.navigate(["/dashboard"]);
          } else if (res.body?.user?.role == 4) {
            this.router.navigate(["/users"]);
          } else if (res.body?.user?.role == 5) {
            this.router.navigate(["/promotion"]);
          }
        }
      },
      (err) => {
        this.pageService.loading.emit(false);
        // this.errorMessage = err?.error?.message;
        if (err?.error?.message === "Invalid Password") {
          this.errorMessage = "Wrong password. Please try again.";
        } else {
          this.errorMessage =
            err?.error?.message || "An error occurred during login.";
        }

        console.log("Error", err);
      }
    );
  }
}
