import { Component, OnInit, SimpleChanges } from "@angular/core";
import { PageServiceService } from "../page-service.service";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "app-promotion",
  templateUrl: "./promotion.component.html",
  styleUrls: ["./promotion.component.css"],
})
export class PromotionComponent implements OnInit {
  promoList: any = [];
  onGoingPromo: any = null;
  //@ts-ignore
  promoForm: FormGroup;
  showError: boolean = false;
  errorMessage: any;

  constructor(
    public pageService: PageServiceService,
    private router: Router,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.getOngoingPromos();
    this.getPromos();
    this.promoForm = this.fb.group({
      promoTitle: ["", Validators.required],
    });
  }
  createForm() {}
  createNewPromo() {
    if (this.promoForm.valid) {
      const promoTitle = this.promoForm.value.promoTitle;
      if (promoTitle.trim() === "") {
        this.showError = true;
      } else {
        this.router.navigate(["/create-new"], {
          queryParams: { title: promoTitle },
        });
      }
    } else {
      this.showError = true;
    }
  }

  getPromos() {
    this.pageService.getPromos().subscribe((res: any) => {
      if (res) {
        this.promoList = res.reverse();
      }
      this.pageService.loading.emit(false);
    });
  }
  getOngoingPromos() {
    this.pageService.getOngoingPromos().subscribe((res: any) => {
      if (res) {
        this.onGoingPromo = res;
      }
      this.pageService.loading.emit(false);
    });
  }
  disablepromo(id: any) {
    this.pageService.disablePromos(id).subscribe(
      (res: any) => {
        if (res) {
          this.getPromos();
        }
      },
      (error) => {
        this.pageService.loading.emit(false);
      }
    );
  }
}
