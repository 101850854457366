<!-- .........content......... -->
<div class="content">
  <div class="row mb-4">
    <div class="cards invite-card me-3">
      <div class="cards-header">
        <h3 class="cards-heading">Create New Promotion</h3>
      </div>
      <div class="invite-content">
        <form
          class="d-flex"
          [formGroup]="promoForm"
          (ngSubmit)="createNewPromo()"
        >
          <div class="input-group mb-2">
            <input
              formControlName="promoTitle"
              type="text"
              class="form-control input-area"
              placeholder="Promo Title"
              aria-label="invite"
              aria-describedby="basic-addon2"
              required
            />
          </div>
          <button type="submit" class="btn invite-btn ms-2">Create</button>
        </form>
        <p class="invit-msg">Promo code will be used to avail discount.</p>
        <p
          class="error-msg"
          *ngIf="
            showError && promoForm.controls['promoTitle'].hasError('required')
          "
        >
          Promo Title Required
        </p>
      </div>
    </div>

    <div class="cards collection-card ms-3">
      <div class="cards-header">
        <h3 class="cards-heading">Ongoing Promotion</h3>
      </div>
      <div class="collection" *ngIf="onGoingPromo && onGoingPromo.length > 0">
        <!-- *ngIf="onGoingPromo" -->
        <div class="d-flex justify-content-between align-items-center">
          <div class="drink-name">{{ onGoingPromo[0]?.promo_code }}</div>
          <div class="availed mx-2">
            Availed by: {{ onGoingPromo[0]?.promo_used?.length || 0 }}
          </div>
        </div>
        <div class="d-flex align-items-center">
          <p class="invit-msg me-3 mt-3">Ending on:</p>
          <p class="invit-msg mt-3">
            {{ onGoingPromo[0]?.end_date | date : "MM/dd/YYYY" }}
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="row mb-4">
    <div class="cards invite-card w-100">
      <div
        class="cards-header d-flex justify-content-between align-items-center"
      >
        <h3 class="cards-heading">Promotions</h3>
      </div>
      <div class="manager-list-wrapper">
        <ng-container *ngFor="let promo of promoList">
          <div
            class="guest-row mobile-hide"
            [ngClass]="{ 'expired-row': promo.isExpired }"
          >
            <!-- <div
              class="currency me- d-flex align-items-center justify-content-center"
            >
              <img src="../../../assets/img/dollar.svg" alt="profile pic" />
            </div> -->
            <div class="payment-width mx-2">
              <h1 class="profile-name my-1">{{ promo?.promo_code }}</h1>
            </div>
            <div class="payment-width mx-2">
              <p class="availed mx-5">{{ promo?.title }}</p>
            </div>
            <p class="availed mx-5">
              Availed by: {{ promo?.promo_used.length }}
            </p>
            <div class="d-flex align-items-center mx-5">
              <p class="date me-3">Start Date:</p>
              <span class="date ms-2">{{
                promo?.start_date | date : "MM/dd/YYYY"
              }}</span>
            </div>
            <div class="d-flex align-items-center mx-5">
              <p class="date me-3">End Date:</p>
              <span class="date ms-2">{{
                promo?.end_date | date : "MM/dd/YYYY"
              }}</span>
            </div>
            <h2 class="amount ms-5">{{ promo?.discount }}%</h2>

            <!-- <div class="wid">
              <div class="tagg guest-tag">
                {{ promo?.role === 3 ? "Guest" : "Business" }}
              </div>
            </div> -->
            <div class="wid ms-5" *ngIf="!promo.isExpired">
              <button
                (click)="disablepromo(promo.id)"
                type="button"
                class="btn invite-btnn"
              >
                {{ promo.status ? "Enable" : "Disable" }}
              </button>
            </div>
            <div class="fix-wid" *ngIf="promo.isExpired"></div>
            <div class="form-check ps-0" *ngIf="errorMessage !== ''">
              <label class="form-check-label error-msg" for="flexCheckDefault">
                {{ errorMessage }}
              </label>
            </div>
          </div>

          <!--.............. mobile design..........  -->
          <div
            class="px-5 py-3 border-bottom mobile-block"
            [ngClass]="{ 'expired-row': promo.isExpired }"
          >
            <div class="d-flex align-items-center mb-4">
              <div
                class="d-flex align-items-center justify-content-center mx-5 w-25"
              >
                <p class="date">
                  {{ promo?.start_date | date : "MM/dd/YYYY" }}
                </p>
              </div>
              <p class="dot ms-2"></p>
              <p class="date">{{ promo?.end_date | date : "MM/dd/YYYY" }}</p>
            </div>
            <div class="d-flex">
              <div
                class="currency me-4 d-flex align-items-center justify-content-center mb-2"
              >
                <img src="../../../assets/img/dollar.svg" alt="profile pic" />
              </div>
              <div class="payment-width">
                <h1 class="profile-name my-1">{{ promo?.promo_code }}</h1>
                <p class="profile-">
                  Availed by: {{ promo?.promo_used.length }}
                </p>
              </div>
              <h2 class="amount">{{ promo?.discount }}%</h2>
            </div>

            <div class="wid-tg-15">
              <div class="tag guest-tag mx-5">
                {{ promo?.role === 3 ? "Guest" : "Business" }}
              </div>
            </div>
            <div class="wid" *ngIf="!promo.isExpired">
              <button
                (click)="disablepromo(promo.id)"
                type="button"
                class="btn invite-btnn"
              >
                {{ promo.isExpired ? "Active" : "Disable" }}
              </button>
            </div>
            <div class="fix-wid" *ngIf="promo.isExpired"></div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
