import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PageServiceService } from "../page-service.service";
@Component({
  selector: "app-guest-newsletter",
  templateUrl: "./guest-newsletter.component.html",
  styleUrls: ["./guest-newsletter.component.css"],
})
export class GuestNewsletterComponent implements OnInit {
  //@ts-ignore
  generalForm: FormGroup;
  isSubmitted: any = false;

  constructor(
    public pageService: PageServiceService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.generalForm = this.formBuilder.group({
      title: ["", Validators.required],
      description: ["", Validators.required],
    });
  }

  get f() {
    return this.generalForm.controls;
  }

  createNewsletter() {
    this.isSubmitted = true;
    if (this.generalForm.invalid) {
      return;
    }
    this.pageService.loading.emit(true);
    const body = {
      title: this.f.title.value,
      body: this.f.description.value,
    };
    this.pageService.addGuestNewletter(body).subscribe((res: any) => {
      if (res) {
        window.location.replace("/Newsletter");
        this.isSubmitted = false;
        this.generalForm.reset();
        this.pageService.loading.emit(false);
      }
    });
  }
}
