import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { PageServiceService } from "../page-service.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "app-view-verify-user",
  templateUrl: "./view-verify-user.component.html",
  styleUrls: ["./view-verify-user.component.css"],
})
export class ViewVerifyUserComponent implements OnInit {
  //@ts-ignore
  userRejectForm: FormGroup;
  baseUrl: string = environment.baseUrl;
  guestId: any;
  guestData: any = [];
  guestIdData: any = [];
  showInput: boolean = false;
  showSubmitButton: boolean = false;
  isSubmitted: any = false;
  userRejectId: any;
  showEnlarged = false;
  enlargedShow = false;
  enlargedImageUrl: string = "";
  enlargedViewStyle: any = {};
  successMessage: string = "";
  showToast: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    public pageService: PageServiceService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getGuestUserData();
    setTimeout(() => {
      this.fetchData();
    }, 2000);
    this.userRejectForm = this.formBuilder.group({
      reajectReason: ["", Validators.required],
    });
  }
  fetchData() {
    this.guestId = this.activatedRoute.snapshot.paramMap.get("id");
    this.guestIdData = this.guestData.find(
      (x: { id: any }) => x.id == this.guestId
    );
    this.userRejectId = this.guestId;
  }
  getGuestUserData() {
    this.pageService.getUnverifyUser().subscribe((res: any) => {
      if (res) {
        this.guestData = res;
      }
      this.pageService.loading.emit(false);
    });
  }
  verifyUser(event: any) {
    this.pageService.getVerifyUser(event.target.id).subscribe((res: any) => {
      if (res) {
        const navigationExtras = {
          queryParams: { successMessage: "User Verify Sucessfully." },
        };
        this.router.navigate(["/verify-users"], navigationExtras);
      }
      this.pageService.loading.emit(false);
    });
  }
  toggleInput(event: any) {
    this.showInput = !this.showInput;
    this.showSubmitButton = true;
  }

  get mf() {
    return this.userRejectForm?.controls;
  }
  userReject() {
    this.isSubmitted = true;
    this.userRejectForm.markAllAsTouched();
    if (this.userRejectForm.invalid) {
      return;
    }
    const body = {
      rejection_reason: this.mf?.reajectReason.value,
    };
    this.pageService
      .getRejectUser(this.userRejectId, body)
      .subscribe((res: any) => {
        if (res) {
          const navigationExtras = {
            queryParams: { successMessage: "User Rejected " },
          };
          this.router.navigate(["/verify-users"], navigationExtras);
        }
      });
  }
  showEnlargedView(imageUrl: string): void {
    this.enlargedImageUrl = imageUrl;
    this.showEnlarged = true;
    this.enlargedViewStyle = {
      background: "rgba(0, 0, 0, 0.7)",
      position: "fixed",
      top: "0",
      left: "500px",
      right: "0",
      bottom: "0",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: "990",
      width: "1000px",
      height: "1000px",
    };
  }
  closeEnlargedView(): void {
    this.showEnlarged = false;
    this.enlargedViewStyle = {};
  }
}
