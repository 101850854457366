<!-- .........content......... -->
<div class="content">
  <div class="row mb-4 mobile-card">
    <div class="cards invite-card me-3">
      <div class="cards-header border-bottom-0">
        <h3 class="cards-heading">Verify Business Documents</h3>
      </div>

      <div class="manager-list-wrapper">
        <ng-container *ngFor="let bus of businessList">
          <div class="manager-items justify-content-between">
            <div class="d-flex">
              <div class="manager-icon">
                <ng-container
                  *ngIf="
                    bus.profile_url && bus.profile_url !== '';
                    else elseTemp1
                  "
                >
                  <img
                    src="{{ baseUrl }}{{ bus.profile_url }}"
                    alt="Business profile pic"
                    class="real-pic"
                  />
                </ng-container>
                <ng-template #elseTemp1>
                  <img src="../../../assets/img/indiviual.svg" alt="managers" />
                </ng-template>
              </div>
              <div class="ms-3">
                <p class="manager-email">Business ID: {{ bus.business?.id }}</p>
                <p class="manager-name">{{ bus.business?.name }}</p>
                <p class="manager-email">{{ bus.business?.address }}</p>
              </div>
            </div>
            <div>
              <button
                class="btn invite-btn ms-2"
                routerLink="/view-verify-document/{{ bus.id }}"
              >
                View Details
              </button>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
