import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AuthServiceService {
  constructor(private http: HttpClient) {}
  login(body: any) {
    return this.http.post(`${environment.baseUrl}api/admin/signin`, body);
  }
  requestToken(body: any) {
    return this.http.post(
      `${environment.baseUrl}administrator/users/send/reset-password/request`,
      body
    );
  }
}
