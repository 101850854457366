<!-- .........content......... -->
<div class="content">
  <div class="row mb-4 mobile-card">
    <div class="cards user-card me-3">
      <app-custom-toast
        [message]="successMessage"
        [showToast]="successMessage !== ''"
      ></app-custom-toast>

      <div class="cards-header border-bottom-0">
        <h3 class="cards-heading">Verify Users</h3>
      </div>
      <div class="manager-list-wrapper">
        <ng-container *ngFor="let user of userList">
          <div class="manager-items justify-content-between">
            <div class="d-flex">
              <div class="manager-icon">
                <ng-container
                  *ngIf="
                    user.profile_url && user.profile_url !== '';
                    else elseTemp
                  "
                >
                  <img
                    src="{{ baseUrl }}{{ user.profile_url }}"
                    alt="managers"
                    class="real-pic"
                  />
                </ng-container>
                <ng-template #elseTemp>
                  <img src="../../../assets/img/indiviual.svg" alt="managers" />
                </ng-template>
              </div>
              <div class="ms-3">
                <p class="manager-name">
                  {{ user?.first_name ? user?.first_name : "N/A" }}
                  {{ user?.last_name ? user?.last_name : "N/A" }}
                </p>
                <p class="manager-email">
                  {{ user?.email ? user?.email : "N/A" }}
                </p>
                <!-- <p class="profile bold-text">
                  User Type: {{ user?.userType }} user
                </p> -->
              </div>
            </div>
            <div>
              <a routerLink="/view-verify-user/{{ user.id }}">
                <button class="btn invite-btn ms-2">View Details</button>
              </a>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="cards user-card ms-3">
      <div class="cards-header border-bottom-0">
        <h3 class="cards-heading">Verify Business</h3>
      </div>
      <div class="manager-list-wrapper">
        <ng-container *ngFor="let bus of businessList">
          <div class="manager-items justify-content-between">
            <div class="d-flex">
              <div class="manager-icon">
                <ng-container
                  *ngIf="
                    bus.profile_url && bus.profile_url !== '';
                    else elseTemp1
                  "
                >
                  <img
                    src="{{ baseUrl }}{{ bus.profile_url }}"
                    alt="managers"
                    class="real-pic"
                  />
                </ng-container>
                <ng-template #elseTemp1>
                  <img src="../../../assets/img/indiviual.svg" alt="managers" />
                </ng-template>
              </div>
              <div class="ms-3">
                <p class="manager-name">{{ bus?.name }}</p>
                <p class="manager-email">{{ bus?.address }}</p>
              </div>
            </div>
            <div>
              <button
                class="btn invite-btn ms-2"
                routerLink="/view-verify-business/{{ bus.id }}"
              >
                View Details
              </button>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
