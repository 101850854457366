<div class="content">
  <div class="row mb-4">
    <div class="cards invite-card w-100">
      <div
        class="cards-header d-flex justify-content-between align-items-center"
      >
        <div class="d-flex align-items-center">
          <a routerLink="/Newsletter">
            <img src="../../../assets/img/left.png" alt="back" width="28px" />
          </a>
          <h3 class="cards-heading mx-3">Business Newsletter</h3>
        </div>
      </div>

      <div class="form-promotion">
        <form [formGroup]="generalForm" (ngSubmit)="createNewsletter()">
          <div class="search">
            <input
              type="text"
              class="form-control input-area input-58"
              placeholder="Newslatter title"
              aria-label="invite"
              aria-describedby="basic-addon2"
              formControlName="title"
            />
            <div *ngIf="isSubmitted && f.title.errors" class="form-error">
              <div *ngIf="f.title.errors.required" class="error-msg">
                Title is required
              </div>
            </div>
          </div>

          <div class="search">
            <textarea
              class="form-control text-area mb-4"
              id="comment"
              name="text"
              placeholder="Newsletter message..."
              formControlName="description"
            ></textarea>
            <div *ngIf="isSubmitted && f.description.errors" class="form-error">
              <div *ngIf="f.description.errors.required" class="error-msg">
                Description is required
              </div>
            </div>
            <p class="profile-message">
              Newslatter will be sent to all the Business throung email.
            </p>
            <button
              type="submit"
              class="btn invite-btn btn-55-height btn-170-wid mt-5"
            >
              Send
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
