import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { PageServiceService } from "src/app/pages/page-service.service";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.css"],
})
export class ForgotPasswordComponent implements OnInit {
  // @ts-ignore
  forgotPasswordForm: FormGroup;
  isSubmitted = false;
  errorMessage: string = "";

  constructor(
    public router: Router,
    public pageService: PageServiceService,
    private formBuilder: FormBuilder
  ) {}

  get formControls() {
    return this.forgotPasswordForm.controls;
  }

  ngOnInit(): void {
    this.forgotPasswordForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      token: ["", [Validators.required]],
      password: ["", Validators.required],
    });
  }

  resetPassword() {
    this.isSubmitted = true;
    this.errorMessage = "";
    if (this.forgotPasswordForm.invalid) {
      return;
    }
    this.pageService.loading.emit(true);
    let body = {
      token: this.formControls.token.value,
      email: this.formControls.email.value,
      newPassword: this.formControls.password.value,
    };
    this.pageService.resetPassword(body).subscribe(
      (res: any) => {
        if (res) {
          this.router.navigate(["/login"]);
          this.pageService.loading.emit(false);
        }
      },
      (err) => {
        this.pageService.loading.emit(false);
        this.errorMessage = err.error.message;
        console.log("Error", err.error.message);
      }
    );
  }
}
