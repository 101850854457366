import { Component, OnInit } from "@angular/core";
import { PageServiceService } from "../page-service.service";
import { environment } from "src/environments/environment";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Roles } from "src/app/utils/roles.enum";
import { Router } from "@angular/router";

@Component({
  selector: "app-verify-documents",
  templateUrl: "./verify-documents.component.html",
  styleUrls: ["./verify-documents.component.css"],
})
export class VerifyDocumentsComponent implements OnInit {
  userList: Array<any> = [];
  businessList: any = [];
  userListData: Array<any> = [];
  businessListData: any = [];
  // @ts-ignore
  inviteForm: FormGroup;
  baseUrl: string = environment.baseUrl;
  userText: any = "";
  businessText: any = "";
  isSubmitted = false;
  selectedItem: string = "Select Role";
  role: number | undefined;
  userIdData: any;
  errorMessage: string = "";
  constructor(
    public pageService: PageServiceService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {
    this.pageService.loading.emit(true);
  }
  get formControls() {
    return this.inviteForm.controls;
  }

  ngOnInit(): void {
    this.inviteForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
    });
    this.getVerifyDoc();
  }

  getVerifyDoc() {
    this.pageService.getverifyBusinessDoc().subscribe((res: any) => {
      if (res) {
        this.businessList = res.data;
      }
      this.pageService.loading.emit(false);
    });
  }
}
