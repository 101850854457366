import { Component, OnInit } from "@angular/core";
import { PageServiceService } from "../page-service.service";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { NgbPaginationConfig } from "@ng-bootstrap/ng-bootstrap";
import { debounceTime } from "rxjs/operators";
import { Subject } from "rxjs";
@Component({
  selector: "app-payment",
  templateUrl: "./payment.component.html",
  styleUrls: ["./payment.component.css"],
})
export class PaymentComponent implements OnInit {
  guestPayment: any = [];
  businessPayment: any = [];
  totalItems: any;
  currentPage = 1;
  errorMessage: string = "";
  userText: any = "";

  private searchTextSubject = new Subject<string>();

  constructor(
    public pageService: PageServiceService,
    private http: HttpClient,
    private config: NgbPaginationConfig
  ) {
    config.maxSize = 20;
    config.pageSize = 10;
  }

  ngOnInit(): void {
    this.getGuestPayment();

    this.searchTextSubject.pipe(debounceTime(700)).subscribe(() => {
      this.getGuestPayment();
    });
  }
  downloadCustomData(dateRange: string) {
    let apiendpoint = `administrator/payments/getGuestPayments?limit=${this.totalItems}&page=0&businessName=`;

    this.http
      .get<any[]>(`${environment.baseUrl}${apiendpoint}`, {
        responseType: "json",
      })
      .subscribe((response: any) => {
        const payments = response.payments;
        const filteredData = payments.filter(
          (data: { doc: string | number | Date }) => {
            const docDate = new Date(data.doc);
            const currentDate = new Date();

            if (dateRange === "today") {
              return docDate.toDateString() === currentDate.toDateString();
            } else if (dateRange === "yesterday") {
              const yesterday = new Date();
              yesterday.setDate(yesterday.getDate() - 1);
              return docDate.toDateString() === yesterday.toDateString();
            } else if (dateRange === "past7days") {
              const past7Days = new Date();
              past7Days.setDate(past7Days.getDate() - 7);
              return docDate >= past7Days && docDate <= currentDate;
            }

            return true;
          }
        );
        if (filteredData.length === 0) {
          this.errorMessage = "No data available for the selected date range.";
          setTimeout(() => {
            this.errorMessage = "";
          }, 3000);
          return;
        }
        const customData = filteredData.map(
          (data: {
            user: {
              id: any;
              first_name: any;
              last_name: any;
              mobile_no: any;
              email: any;
            };
            bus_id: any;
            order: {
              business: any;
              order_drinks: any;
              order_status: any;
              id: any;
              final_price: any;
            };
            amount: any;
            doc: any;
            refunds: any[];
          }) => ({
            "User ID": data.user.id,
            "First Name": data.user.first_name,
            "Last Name": data.user.last_name,
            "Mobile Number": data.user.mobile_no,
            Email: data.user.email,
            "Business ID": data.bus_id,
            "Business Name": data.order.business.name,
            "Order ID": data.order.id,
            "Final Price": data.order.final_price,
            "Order Status": data.order.order_status,
            // "Refund Type":
            //   data.refunds.length > 0
            //     ? data.refunds[0].metadata.refund_type
            //     : "N/A",
            "Drinks Name": data.order.order_drinks
              .map(
                (drink: { drink_size_options: { drinks: { name: any } } }) =>
                  drink.drink_size_options.drinks.name
              )
              .join(", "),

            Amount: data.amount,
            "Order Date": data.doc,
          })
        );
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(customData);
        const range = XLSX.utils.decode_range(worksheet["!ref"] || "");
        for (let row = range.s.r; row <= range.e.r; row++) {
          for (let col = range.s.c; col <= range.e.c; col++) {
            const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
            const nextCellAddress = XLSX.utils.encode_cell({
              r: row,
              c: col + 1,
            });
            worksheet[cellAddress] = worksheet[nextCellAddress];
          }
        }
        range.e.c -= 1;
        worksheet["!ref"] = XLSX.utils.encode_range(range);

        const workbook: XLSX.WorkBook = {
          Sheets: { data: worksheet },
          SheetNames: ["data"],
        };
        const excelBuffer: any = XLSX.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });
        const file = new Blob([excelBuffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(file, "Guest Payment.xlsx");
      });
  }

  getGuestPayment() {
    const pageLimit = 20;

    this.pageService
      .getGuestPayment(pageLimit, this.currentPage, this.userText)
      .subscribe((response: any) => {
        this.guestPayment = response.payments;
        this.totalItems = response.pagination.totalItems;
      });
  }
  pageChange(newPage: number): void {
    this.currentPage = newPage;
    this.getGuestPayment();
  }
  searchUsers() {
    this.searchTextSubject.next();
  }
  getBusinessPayment() {
    this.pageService.getBusinessPayment().subscribe((res: any) => {
      if (res) {
        this.businessPayment = res;
      }
    });
  }
}
