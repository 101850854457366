import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PageServiceService } from "../page-service.service";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.css"],
})
export class ChangePasswordComponent implements OnInit {
  //@ts-ignore
  generalForm: FormGroup;
  isSubmitted: any = false;
  showOldPassword: boolean = false;
  showNewPassword: boolean = false;
  showConfirmPassword: boolean = false;
  passwordsMatch: boolean = true;

  constructor(
    public pageService: PageServiceService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.generalForm = this.formBuilder.group({
      OldPassword: ["", Validators.required],
      NewPassword: ["", Validators.required],
      ConfirmPassword: ["", Validators.required],
    });
  }
  get f() {
    return this.generalForm.controls;
  }
  toggleOldPasswordVisibility() {
    this.showOldPassword = !this.showOldPassword;
  }
  toggleNewPasswordVisibility() {
    this.showNewPassword = !this.showNewPassword;
  }

  toggleConfirmPasswordVisibility() {
    this.showConfirmPassword = !this.showConfirmPassword;
  }
  checkPasswordMatch() {
    const newPassword = this.generalForm.get("NewPassword")?.value;
    const confirmPassword = this.generalForm.get("ConfirmPassword")?.value;
    this.passwordsMatch = newPassword === confirmPassword;
  }
  changePassword() {
    this.isSubmitted = true;
    if (this.generalForm.invalid) {
      return;
    }

    var storedData = localStorage.getItem("user");
    var parsedData = storedData ? JSON.parse(storedData) : null;
    var id = parsedData.id;

    this.pageService.loading.emit(true);

    const body = {
      id: id,
      current_password: this.f.OldPassword.value,
      password: this.f.NewPassword.value,
    };
    this.pageService.changePassword(body).subscribe((res: any) => {
      if (res) {
        this.isSubmitted = false;
        this.generalForm.reset();
        window.location.replace("/login");
        this.pageService.loading.emit(false);
      }
    });
  }
}
