<div class="content-page">
    <h1 class="page-heading">
        Privacy Policy
    </h1>
    <div class="content">
        <b>Effective Date: April ___, 2021</b>
        <p class="mt-4">This privacy policy (this “Policy”) explains how personal information is collected, used, and
            disclosed by The Drink App LLC (“TDA” or “we”). This Policy applies to consumer users (individually referred
            to as “you”) of TDA websites, the TDA mobile application (the “App”), and the services accessible through
            the Website or App (collectively, our “Sites”).</p>
        <p class="mt-2">We reserve the right to change this Policy at any time. We will notify you that changes have
            been made by indicating on this Policy the date it was last updated. We encourage you to review this Policy
            from time-to-time to make sure you understand how any personal information you provide to us or collected
            about you will be used.</p>
        <p class="mt-2">Other third parties, including without limitation, nightclubs and lounges that market and/or
            provide services on the Sites, and third party payment services integrated into the Sites, may also collect,
            use, and share information about you. This Policy does not cover such third parties or their services. For
            more information about third-party privacy practices, please contact them directly.</p>

        <h6 class="mt-4"><b>INFORMATION WE COLLECT</b></h6>
        <p class="mt-4">We collect information about you in various ways when you use our Sites. We use this information
            for a variety of reasons, including but not limited to providing the functionality and improving the quality
            of our Sites and personalizing your overall experience.</p>
        <p class="mt-2"><b>Active Collection.</b> We collect information from you when you use the Sites. For example,
            when you create an account on our Sites, we may ask you to provide your name, email address, postal address,
            phone number(s), and other information. You may choose not to provide certain personal information. However,
            if you decide to do so, you may not be able to take advantage of the Service.</p>
        <p class="mt-2">We collect financial information, such as your payment method (i.e. valid credit card number,
            type, expiration date or other financial information) only through a third party payment process integrated
            into our App. That information is collected and stored by our third party payment processing company (the
            “Payment Processor”), and the subsequent use and storage of information is governed by the Payment
            Processor’s applicable terms of service and privacy policy.</p>

        <p class="mt-4"><b>Passive Collection.</b> When you use the Service, some information is also automatically
            collected, such as your Internet Protocol (IP) address, your operating system, the browser type, the address
            of a referring website, and your activity on the Service. We also may automatically collect certain
            logistical information in server logs, including information about how you use various features of the
            Service and information about the number, frequency and length of each session.</p>
        <p class="mt-2">We also may automatically collect certain information through the use of “cookies.” Cookies are
            small data files stored on your hard drive at the request of a website. Among other things, cookies help us
            to improve the Service and your experience. If you wish to block, erase, or be warned of cookies, please
            refer to your browser manufacturer to learn about these functions. However, if you choose to remove or
            reject cookies, this could affect certain features or services made available on the Sites.</p>
        <p class="mt-2">Currently, we do not employ technology that recognizes “do-not-track” signals. We may engage
            third parties, such as analytics or marketing partners, who may collect information about your online
            activities over time and across different websites when you use our Sites.</p>
        <p class="mt-2">We may collect device-specific information (i.e. operating system version, your hardware model,
            unique device identifiers, and mobile network information including phone number). We may associate your
            device identifiers or phone number with your TDA account.</p>
        <p class="mt-2">We may use Web beacons or similar technology on the Sites. Web beacons are small, invisible
            graphic images that may be used on the Sites to collect certain information and monitor user activity, such
            as to count visits and understand usage effectiveness.</p>
        <p class="mt-2">Personal information will be stored on secured servers, and we may store and process personal
            information in the United States and other countries.</p>

        <h6 class="mt-4"><b>HOW WE USE INFORMATION WE COLLECT</b></h6>
        <p class="mt-4">We use personal information collected through our Sites for the purposes described in this
            Policy or disclosed to you on our Sites or otherwise in connection with our services. For example, we may
            use your information to:</p>
        <p class="mt-2">• to request feedback and to otherwise contact you about your use of the Service;</p>
        <p class="mt-1">• process and complete transactions in connection with the Service (e.g. to make premium
            services of nightclubs and lounges accessible to you);</p>
        <p class="mt-1">• to monitor and analyze Site usage and trends;</p>
        <p class="mt-1">• to respond to your emails, questions, comments, requests and complaints, and to provide
            customer service;</p>
        <p class="mt-1">• to provide you with news and information about our or third-party events, offers, promotions
            and services we think interest you;</p>
        <p class="mt-1">• to personalize and improve the Sites, and our users’ experiences with the Sites (such as
            providing content or features that match interests), and to increase the Sites’ functionality and user
            friendliness;</p>
        <p class="mt-1">• to send you confirmations, updates, security alerts and support and administrative messages,
            and otherwise facilitate your use of, and our administration and operation of, the Sites;</p>
        <p class="mt-1">• to notify you about important changes to the service; and</p>
        <p class="mt-1">• for any other purpose for which the information was collected.</p>

        <h6 class="mt-4"><b>HOW WE SHARE INFORMATION</b></h6>
        <p class="mt-4">When you purchase access to premium services of nightclubs and lounges (the “Venues”) through
            our App, we provide your personal information to such Venues to facilitate your access to the premium
            services. TDA does not control the privacy practices of Venues. Please contact the Venue directly if you
            want to learn about its privacy practices.</p>
        <p class="mt-2">We do not share your personal information with third parties other than as described above and
            as follows:</p>
        <p class="mt-2">We may share aggregate statistical data for the improvement of services offered by our Sites. We
            may share personal information with affiliates, third-party vendors, consultants, and other service
            providers who work for us. Such third-party vendors may include vendors who provide fraud detection services
            to us and other third parties.</p>
        <p class="mt-4">We share personal information with third parties who provide services to us, such as data
            collection, reporting, ad response measurement, and site analytics, as well as assistance with delivery of
            relevant marketing and advertising. These third parties may view, edit, or set their own cookies. We and our
            third-party service providers, advertisers, and/or partners may also place web beacons for these third
            parties. The use of these technologies by these third parties is subject to their own privacy policies and
            is not covered by this Policy. We may also share with third parties aggregated or de-identified information
            that does not identify you.</p>
        <p class="mt-2">We may disclose or transfer your personal information to a third party if we sell, transfer,
            divest, or disclose all or a portion of our business or assets to another company in connection with or
            during negotiation of any merger, financing, or acquisition transaction, or any bankruptcy or dissolution
            proceeding.</p>
        <p class="mt-2">We may disclose your personal information for the following reasons: (i) to comply with laws and
            to respond to lawful requests and legal process, (ii) to protect the rights and property of TDA, our agents
            and customers, and others, including to enforce our agreements, policies, and terms of use, and (iii) in an
            emergency to protect the personal safety of you, TDA customers, or any other person.</p>

        <h6 class="mt-4"><b>LINKS TO OTHER WEBSITES</b></h6>
        <p class="mt-4">The Sites may contain links to other websites. Any personal information you provide on the
            linked pages is provided directly to that third party and is subject to that third party’s privacy policy.
        </p>
        <p class="mt-2">This Policy does not apply to such linked websites, and we are not responsible for the content
            or privacy and security practices and policies of these websites or any other websites that are linked
            to/from the Sites. We encourage you to learn about their privacy and security practices and policies before
            providing them with personally identifiable information.</p>

        <h6 class="mt-4"><b>SECURITY OF YOUR PERSONAL INFORMATION</b></h6>
        <p class="mt-4">We always take reasonable measures to protect your personal information to prevent misuse and
            loss, as well as any unauthorized access, disclosure, alteration, and destruction. However, please be aware
            that despite our best efforts, no security measures are perfect or impenetrable. Therefore, no method of
            data transmission can guarantee against any interception or any other type of misuse. To assist with the
            protection of personal information, you must keep your password confidential and not disclose it to any
            other person. You are responsible for all uses of the Sites and our services by any person using your
            password. Please advise us immediately if you believe your password has been misused in any manner.</p>

        <h6 class="mt-4"><b>YOUR INFORMATION CHOICES AND CHANGES</b></h6>
        <p class="mt-4">In the event we decide to use your personal information for any purpose (other than as
            referenced in this Policy), we will offer you an effective way to opt out of the use of your personal
            information for those other purposes. You may opt out of receiving promotional emails from TDA by following
            the instructions in those emails. If you opt out, we may still send you non-promotional emails, such as
            emails about your TDA account or our ongoing business relations.</p>

        <h6 class="mt-4"><b>CONTACTING TDA</b></h6>
        <p class="mt-4">For questions about this Policy or accessing, changing, or deleting your personal information
            you may contact TDA at 781.742.5506 or at <a href="javascript:void(0)">DrinkApp2020@gmail.com.</a></p>

        <div class="btn-wrapper mt-5">
            <button type="button" class="btn btn-site me-2">Decline</button>
            <button type="button" class="btn btn-site primary-btn ms-2">Accept</button>
        </div>

    </div>
</div>