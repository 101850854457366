<div class="content card">
  <div class="d-flex align-items-center pb-4">
    <a routerLink="/verify-docments">
      <img src="../../../assets/img/left.png" alt="back" width="28px" />
    </a>

    <h3 class="cards-heading mx-3">Verify Business Document</h3>
  </div>

  <div class="cards user-card me-3">
    <div class="cards-header border-bottom-0">
      <h3 class="cards-heading">Business Name</h3>
    </div>
    <div class="padding">
      <input
        type="text"
        class="form-control input-area"
        placeholder="Business Name"
        aria-label="invite"
        aria-describedby="basic-addon2"
        value="{{ businessIdData.business?.name }}"
        disabled
      />
    </div>

    <div class="cards-header border-bottom-0">
      <h3 class="cards-heading">Address</h3>
    </div>
    <div class="padding">
      <input
        type="text"
        class="form-control input-area"
        placeholder="Address"
        aria-label="invite"
        aria-describedby="basic-addon2"
        value="{{ businessIdData.business?.address }}"
        disabled
      />
    </div>

    <div class="row mb-4 p-3">
      <h3 class="cards-heading">liquor and Business Certificate</h3>

      <div class="image-container">
        <img
          src="{{ baseUrl }}{{ businessIdData?.liq_cert }}"
          (click)="showEnlargedView()"
        />
        <div
          class="enlarged-view"
          [ngStyle]="enlargedViewStyle"
          *ngIf="showEnlarged"
        >
          <span class="close-icon" (click)="closeEnlargedView()">&times;</span>
          <img
            src="{{ baseUrl }}{{ businessIdData?.liq_cert }}"
            alt="Enlarged Image"
          />
        </div>
      </div>

      <div class="image-container">
        <img
          src="{{ baseUrl }}{{ businessIdData?.bus_cert }}"
          (click)="showEnlargedView()"
        />
        <div
          class="enlarged-view"
          [ngStyle]="enlargedViewStyle"
          *ngIf="showEnlarged"
        >
          <span class="close-icon" (click)="closeEnlargedView()">&times;</span>
          <img
            src="{{ baseUrl }}{{ businessIdData?.bus_cert }}"
            alt="Enlarged Image"
          />
        </div>
      </div>
    </div>
    <button
      (click)="verifyBusinessDoc($event, true)"
      id="{{ businessIdData.business?.id }}"
      class="btn invite-btn btn-55-height btn-170-wid mt-5 mb-4 mx-3"
    >
      Verify
    </button>
    <button
      (click)="verifyBusinessDoc($event, false)"
      id="{{ businessIdData.business?.id }}"
      class="btn danger-btn btn-55-height btn-170-wid mt-5 mb-4 mx-3"
    >
      Reject
    </button>
  </div>
</div>
