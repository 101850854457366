import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { PageServiceService } from "../page-service.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.css"],
})
export class ProfileComponent implements OnInit {
  userData: any = {};
  baseUrl: string = environment.baseUrl;
  showEnlarged = false;
  enlargedShow = false;
  enlargedImageUrl: string = "";

  enlargedViewStyle: any = {};
  constructor(
    public pageService: PageServiceService,
    public activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.queryParams.subscribe((params: any) => {
      if (JSON.parse(params?.user)?.type === "user") {
        let obj = {
          profile_url: JSON.parse(params?.user)?.profile_url,
          first_name: JSON.parse(params?.user)?.first_name
            ? JSON.parse(params?.user)?.first_name
            : "N/A",
          last_name: JSON.parse(params?.user)?.last_name
            ? JSON.parse(params?.user)?.last_name
            : "N/A",
          email: JSON.parse(params?.user)?.email,
          doc_pic_back: JSON.parse(params?.user)?.doc_pic_back,
          doc_pic_front: JSON.parse(params?.user)?.doc_pic_front,
          role: "Guest",
        };
        this.userData = obj;
      }

      if (JSON.parse(params?.user)?.type === "business") {
        let obj = {
          profile_url: JSON.parse(params?.user)?.profile_url,
          first_name: JSON.parse(params?.user)?.first_name
            ? JSON.parse(params?.user)?.first_name
            : "N/A",
          last_name: JSON.parse(params?.user)?.last_name
            ? JSON.parse(params?.user)?.last_name
            : "N/A",
          email: JSON.parse(params?.user)?.email,
          doc_pic_back: JSON.parse(params?.user)?.bus_cert,
          doc_pic_front: JSON.parse(params?.user)?.liq_cert,
          role: "Business",
        };
        this.userData = obj;
      }
    });
  }

  ngOnInit(): void {}

  showEnlargedView(imageUrl: string): void {
    this.enlargedImageUrl = imageUrl;
    this.showEnlarged = true;
    this.enlargedViewStyle = {
      background: "rgba(0, 0, 0, 0.7)",
      position: "fixed",
      top: "0",
      left: "500px",
      right: "0",
      bottom: "0",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: "990",
      width: "1000px",
      height: "1000px",
    };
  }
  closeEnlargedView(): void {
    this.showEnlarged = false;
    this.enlargedViewStyle = {};
  }
}
