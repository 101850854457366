import { EventEmitter, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
@Injectable({
  providedIn: "root",
})
export class PageServiceService {
  public userSubject: BehaviorSubject<any>;
  public user: Observable<any>;
  public loading: any = new EventEmitter();
  constructor(private router: Router, private http: HttpClient) {
    this.userSubject = new BehaviorSubject(
      JSON.parse(localStorage.getItem("user") || "{}")
    );
    this.user = this.userSubject.asObservable();
  }
  logout() {
    localStorage.removeItem("authToken");
    localStorage.removeItem("user");
    this.userSubject.next(null);
    this.router.navigate(["/login"]);
  }
  getusers() {
    return this.http.get(`${environment.baseUrl}administrator/users`);
  }
  updateGuestUser(body: any) {
    return this.http.patch(
      `${environment.baseUrl}administrator/users/edit/guest`,
      body
    );
  }
  updateBusinessUser(body: any) {
    return this.http.patch(
      `${environment.baseUrl}administrator/users/edit/business`,
      body
    );
  }
  getBusiness() {
    return this.http.get(`${environment.baseUrl}administrator/business`);
  }
  sentInvite(body: any) {
    return this.http.post(`${environment.baseUrl}administrator/invites`, body);
  }
  getusersbyrole(body: any) {
    return this.http.post(
      `${environment.baseUrl}api/admin/userListByRole`,
      body
    );
  }
  getqueryList() {
    return this.http.get(`${environment.baseUrl}api/admin/queriesList`);
  }
  sendReply(body: any) {
    return this.http.post(`${environment.baseUrl}api/admin/sendReply`, body);
  }
  getqueryReplies(support_id: any) {
    return this.http.get(
      `${environment.baseUrl}api/admin/queryReplies?support_id=${support_id}`
    );
  }

  createPromos(body: any) {
    return this.http.post(
      `${environment.baseUrl}administrator/promos/create`,
      body
    );
  }
  getPromos() {
    return this.http.get(
      `${environment.baseUrl}administrator/promos/getPromos`
    );
  }
  getOngoingPromos() {
    return this.http.get(
      `${environment.baseUrl}administrator/promos/getOngoingPromos`
    );
  }
  disablePromos(id: any) {
    return this.http.patch(
      `${environment.baseUrl}administrator/promos/disablePromo/${id}`,
      {}
    );
  }
  getGuestNewsletter() {
    return this.http.get(
      `${environment.baseUrl}administrator/newsletter/getAll/guests`
    );
  }
  getBusinessNewsletter() {
    return this.http.get(
      `${environment.baseUrl}administrator/newsletter/getAll/business`
    );
  }
  addGuestNewletter(body: any) {
    return this.http.post(
      `${environment.baseUrl}administrator/newsletter/create/guest`,
      body
    );
  }
  addBusinessNewletter(body: any) {
    return this.http.post(
      `${environment.baseUrl}administrator/newsletter/create/business`,
      body
    );
  }
  deleteNewsletter(id: any) {
    return this.http.delete(
      `${environment.baseUrl}administrator/newsletter/${id}`
    );
  }
  updateNewsletter(body: any) {
    return this.http.patch(
      `${environment.baseUrl}administrator/newsletter`,
      body
    );
  }
  getGuestPayment(pageLimit: any, currentPage: any, filter: any) {
    return this.http.get(
      `${
        environment.baseUrl
      }administrator/payments/getGuestPayments?limit=${pageLimit}&page=${
        currentPage - 1
      }&businessName=${filter}`
    );
  }
  getBusinessPayment() {
    return this.http.get(
      `${environment.baseUrl}administrator/payments/getBusinessPayments`
    );
  }
  requestToken(body: any) {
    return this.http.post(
      `${environment.baseUrl}administrator/users/send/reset-password/request`,
      body
    );
  }
  resetPassword(body: any) {
    return this.http.post(
      `${environment.baseUrl}administrator/users/reset-password`,
      body
    );
  }

  addStyleIce(body: any) {
    return this.http.post(
      `${environment.baseUrl}administrator/style-ice`,
      body
    );
  }
  getStyleIce() {
    return this.http.get(`${environment.baseUrl}administrator/style-ice`);
  }
  deleteStyleIce(id: any) {
    return this.http.delete(
      `${environment.baseUrl}administrator/style-ice/${id}`
    );
  }

  addMixer(body: any) {
    return this.http.post(`${environment.baseUrl}administrator/mixers`, body);
  }
  getMixer() {
    return this.http.get(`${environment.baseUrl}administrator/mixers`);
  }
  deleteMixer(id: any) {
    return this.http.delete(`${environment.baseUrl}administrator/mixers/${id}`);
  }
  addCategories(body: any) {
    return this.http.post(
      `${environment.baseUrl}administrator/categories`,
      body
    );
  }
  getCategories() {
    return this.http.get(`${environment.baseUrl}administrator/categories`);
  }
  deleteCategories(id: any) {
    return this.http.delete(
      `${environment.baseUrl}administrator/categories/${id}`
    );
  }
  addSubCategories(body: any) {
    return this.http.post(
      `${environment.baseUrl}administrator/subcategories`,
      body
    );
  }
  getSubCategories() {
    return this.http.get(`${environment.baseUrl}administrator/subcategories`);
  }
  deleteSubCategories(id: any) {
    return this.http.delete(
      `${environment.baseUrl}administrator/subcategories/${id}`
    );
  }
  getTodayCount() {
    return this.http.get(`${environment.baseUrl}administrator/payments/today`);
  }
  getManager() {
    return this.http.get(`${environment.baseUrl}administrator/users/managers`);
  }
  updateManager(body: any) {
    return this.http.patch(
      `${environment.baseUrl}administrator/users/edit/manager`,
      body
    );
  }
  getEditor() {
    return this.http.get(`${environment.baseUrl}administrator/users/editors`);
  }
  updateEditor(body: any) {
    return this.http.patch(
      `${environment.baseUrl}administrator/users/edit/editor`,
      body
    );
  }
  enableUser(body: any) {
    return this.http.put(
      `${environment.baseUrl}administrator/users/${body}/enable`,
      body
    );
  }
  disableUser(body: any) {
    return this.http.put(
      `${environment.baseUrl}administrator/users/${body}/disable`,
      body
    );
  }
  getUnverifyUser() {
    return this.http.get(
      `${environment.baseUrl}administrator/users/un-verified`
    );
  }
  getUnverifyBusiness() {
    return this.http.get(
      `${environment.baseUrl}administrator/business/un-verified`
    );
  }
  getVerifyUser(id: any) {
    return this.http.post(
      `${environment.baseUrl}administrator/users/verifyUser/${id}`,
      {}
    );
  }
  getRejectUser(id: any, body: any) {
    return this.http.post(
      `${environment.baseUrl}administrator/users/rejectUser/${id}`,
      body
    );
  }
  getVerifyBusiness(id: any) {
    return this.http.post(
      `${environment.baseUrl}administrator/business/verifyBusiness/${id}`,
      {}
    );
  }
  getRejectBusiness(id: any, body: any) {
    return this.http.post(
      `${environment.baseUrl}administrator/business/rejectBusiness/${id}`,
      body
    );
  }
  getverifyBusinessDoc() {
    return this.http.get(
      `${environment.baseUrl}api/bussiness/getpending_verify`
    );
  }
  getVerifyBusinessDoc(id: any, body: any) {
    return this.http.post(
      `${environment.baseUrl}api/bussiness/pending_verify/?id=${id}`,
      body
    );
  }
  updateSubCategory(id: any, body: any) {
    return this.http.patch(
      `${environment.baseUrl}administrator/subcategories/${id}`,
      body
    );
  }
  updateCategory(id: any, body: any) {
    return this.http.patch(
      `${environment.baseUrl}administrator/categories/${id}`,
      body
    );
  }
  changePassword(body: any) {
    return this.http.post(`${environment.baseUrl}admin/changepassword`, body);
  }
  addDrinkSetting(body: any) {
    return this.http.post(
      `${environment.baseUrl}api/admin/drinks/details`,
      body
    );
  }
  EnableDisableBusiness(id: any, value: any, body: any) {
    return this.http.post(
      `${environment.baseUrl}administrator/business/enable-disable/${id}?is_enabled=${value}`,

      body
    );
  }
  getGuestPaymentById(id: any) {
    return this.http.get(
      `${environment.baseUrl}administrator/payments/getGuestPayments/${id}`
    );
  }
}
