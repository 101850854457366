<!-- .........content......... -->
<div class="content">
  <div class="row mb-4">
    <div class="cards invite-card">
      <div
        class="cards-header d-flex justify-content-between align-items-center"
      >
        <div class="d-flex align-items-center">
          <a routerLink="/promotion">
            <img src="../../../assets/img/left.png" alt="back" width="28px" />
          </a>

          <h3 class="cards-heading mx-3">Create New Promotion</h3>
        </div>
      </div>

      <div class="form-promotion">
        <form [formGroup]="generalForm" (ngSubmit)="createPromo()">
          <div class="search">
            <input
              type="text"
              class="form-control input-58 input-area"
              formControlName="promoTitle"
              placeholder="Promo Title"
              aria-label="invite"
              aria-describedby="basic-addon2"
            />
            <div *ngIf="isSubmitted && f.promoCode.errors" class="form-error">
              <div *ngIf="f.promoCode.errors.required" class="error-msg">
                Promo title is required
              </div>
            </div>
          </div>
          <div class="search">
            <input
              type="text"
              class="form-control input-58 input-area"
              formControlName="promoCode"
              placeholder="Promo Code"
              aria-label="invite"
              aria-describedby="basic-addon2"
            />
            <div *ngIf="isSubmitted && f.promoCode.errors" class="form-error">
              <div *ngIf="f.promoCode.errors.required" class="error-msg">
                Promo code is required
              </div>
            </div>
          </div>
          <div class="search">
            <input
              type="number"
              class="form-control input-area input-58"
              formControlName="discount"
              placeholder="Discount applied in % up to 100"
              aria-label="invite"
              aria-describedby="basic-addon2"
            />
            <div *ngIf="isSubmitted && f.discount.errors" class="form-error">
              <div *ngIf="f.discount.errors.required" class="error-msg">
                Discount is required
              </div>
            </div>
            <div *ngIf="isSubmitted" class="form-error">
              <div *ngIf="f.discount.value > 100" class="error-msg">
                Discount can't be greater then 100%
              </div>
            </div>
          </div>
          <div class="p-3">
            <select
              #role
              class="form-select form-control input-58 input-area"
              aria-label="Default select example"
              (change)="setSelectedItem(role.value)"
            >
              <option
                [value]="'Select promotion code for'"
                [selected]="selectedItem === 'Select promotion code for'"
              >
                {{ selectedItem }}
              </option>
              <option value="3" [selected]="selectedItem === '3'">
                Guests
              </option>
              <option value="2" [selected]="selectedItem === '2'">
                Business
              </option>
            </select>
          </div>

          <div class="d-flex justify-content-between mobile-flex">
            <div class="search wid-45">
              <input
                formControlName="from_date"
                placeholder="Start Date"
                type="date"
                min="{{ getTodaysDate() }}"
                class="form-control input-area input-58"
                aria-label="invite"
                aria-describedby="basic-addon2"
              />
              <div *ngIf="isSubmitted && f.from_date.errors" class="form-error">
                <div *ngIf="f.from_date.errors.required" class="error-msg">
                  From Date is required
                </div>
              </div>
            </div>
            <div class="search wid-45">
              <input
                formControlName="to_date"
                placeholder="End Date"
                type="date"
                min="{{ f.from_date.value }}"
                class="form-control input-area input-58"
                aria-label="invite"
                aria-describedby="basic-addon2"
              />
              <div *ngIf="isSubmitted && f.to_date.errors" class="form-error">
                <div *ngIf="f.to_date.errors.required" class="error-msg">
                  To Date is required
                </div>
              </div>
            </div>
          </div>
          <button
            type="submit"
            class="btn invite-btn mt-5 btn-170-wid btn-55-height ms-3"
          >
            Create & Publish
          </button>
        </form>
      </div>
      <div class="form-check ps-0" *ngIf="errorMessage !== ''">
        <label class="form-check-label error-msg" for="flexCheckDefault">
          {{ errorMessage }}
        </label>
      </div>
    </div>
  </div>
</div>
