<div class="content card">
  <div class="d-flex align-items-center pb-4">
    <a routerLink="/dashboard">
      <img src="../../../assets/img/left.png" alt="back" width="28px" />
    </a>

    <h3 class="cards-heading mx-3">View Manager</h3>
  </div>
  <div class="row mb-4">
    <div class="h-10 w-10">
      <img
        src="{{ baseUrl }}{{ managerIdData?.profile_url }}"
        alt="Users"
        class="real-pic"
      />
    </div>
  </div>

  <div class="cards user-card me-3">
    <div class="cards-header border-bottom-0">
      <h3 class="cards-heading">User Name</h3>
    </div>
    <div class="padding">
      <input
        type="text"
        class="form-control input-area"
        placeholder="User Name"
        aria-label="invite"
        aria-describedby="basic-addon2"
        value="{{ managerIdData?.user_name }}"
        disabled
      />
    </div>
    <div class="cards-header border-bottom-0">
      <h3 class="cards-heading">First Name</h3>
    </div>
    <div class="padding">
      <input
        type="text"
        class="form-control input-area"
        placeholder="First Name"
        aria-label="invite"
        aria-describedby="basic-addon2"
        value="{{ managerIdData?.first_name }}"
        disabled
      />
    </div>
    <div class="cards-header border-bottom-0">
      <h3 class="cards-heading">Last Name</h3>
    </div>
    <div class="padding">
      <input
        type="text"
        class="form-control input-area"
        placeholder="Last Name"
        aria-label="invite"
        aria-describedby="basic-addon2"
        value="{{ managerIdData?.last_name }}"
        disabled
      />
    </div>
    <div class="cards-header border-bottom-0">
      <h3 class="cards-heading">Email</h3>
    </div>
    <div class="padding">
      <input
        type="text"
        class="form-control input-area"
        placeholder="Email"
        aria-label="invite"
        aria-describedby="basic-addon2"
        value="{{ managerIdData?.email }}"
        disabled
      />
    </div>
    <div class="cards-header border-bottom-0">
      <h3 class="cards-heading">Address</h3>
    </div>
    <div class="padding">
      <input
        type="text"
        class="form-control input-area"
        placeholder="Address"
        aria-label="invite"
        aria-describedby="basic-addon2"
        value="{{ managerIdData?.address1 }}"
        disabled
      />
    </div>
  </div>
</div>
