<div class="header">
  <div class="mobile-header">
    <div class="logo-wrapper">
      <img
        src="../../../assets/img/login-logo.svg"
        alt="logo"
        class="logo-sidebar"
      />
    </div>
  </div>
  <div class="d-flex align-items-center">
    <span class="menu" (click)="openNav()">&#9776;</span>

    <button type="button" class="btn logout-btn" (click)="logout()">
      Logout
    </button>
  </div>
</div>
