import { Component, OnInit } from "@angular/core";
import { PageServiceService } from "../page-service.service";
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  businessList: any = [];
  userList: any = [];
  user: any;
  business: any;
  sidebarVisible: boolean = false;

  constructor(public pageService: PageServiceService) {}

  ngOnInit(): void {
    // this.getusers();
    // this.getBusiness();
  }

  logout() {
    this.pageService.logout();
  }
  openNav() {
    (document.getElementById("sideNavbar") as HTMLElement).style.display =
      "block";
  }
  closeNav() {
    (document.getElementById("sideNavbar") as HTMLElement).style.display =
      "none";
  }

  getusers() {
    this.pageService.getUnverifyUser().subscribe((res: any) => {
      if (res) {
        this.userList = res;
        this.user = this.userList.length;
      }
    });
  }
  getBusiness() {
    this.pageService.getUnverifyBusiness().subscribe((res: any) => {
      if (res) {
        this.businessList = res;
        this.business = this.businessList.length;
      }
      this.pageService.loading.emit(false);
    });
  }
}
