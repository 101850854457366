import { Component, OnInit } from "@angular/core";
import { PageServiceService } from "../page-service.service";
import { environment } from "src/environments/environment";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Roles } from "src/app/utils/roles.enum";

@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.css"],
})
export class UsersComponent implements OnInit {
  userList: Array<any> = [];
  businessList: any = [];
  userListData: Array<any> = [];
  businessListData: any = [];
  // @ts-ignore
  inviteForm: FormGroup;
  baseUrl: string = environment.baseUrl;
  userText: any = "";
  businessText: any = "";
  isSubmitted = false;
  selectedItem: string = "Select Role";
  role: number | undefined;
  userIdData: any;
  errorMessage: string = "";
  managerStatus: any;
  constructor(
    public pageService: PageServiceService,
    private formBuilder: FormBuilder
  ) {
    this.pageService.loading.emit(true);
  }
  get formControls() {
    return this.inviteForm.controls;
  }

  ngOnInit(): void {
    this.inviteForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
    });
    this.getusers();
    this.getBusiness();
  }
  setSelectedItem(option: string) {
    this.selectedItem = option;
    this.role =
      option == "Manager"
        ? Roles.MANAGER
        : option == "Editor"
        ? Roles.EDITOR
        : 0;
  }

  getusers() {
    this.pageService.getusers().subscribe((res: any) => {
      if (res) {
        this.userList = res;
        this.userListData = res;
      }
    });
  }
  getBusiness() {
    this.pageService.getBusiness().subscribe((res: any) => {
      if (res) {
        this.businessList = res.businesses;
        this.businessListData = res.businesses;
      }
      this.pageService.loading.emit(false);
    });
  }
  searchUsers() {
    const data = this.userListData.filter((v, i) => {
      return (
        v["first_name"]?.toLowerCase().includes(this.userText.toLowerCase()) ||
        v["last_name"]?.toLowerCase().includes(this.userText.toLowerCase()) ||
        v.email?.toLowerCase().includes(this.userText.toLowerCase()) ||
        v.userType?.toLowerCase().includes(this.userText.toLowerCase())
      );
    });
    this.userList = data;
  }
  searchBusiness() {
    const data = this.businessListData.filter((v: any, i: any) => {
      return (
        v["address"]?.toLowerCase().includes(this.businessText.toLowerCase()) ||
        v["name"]?.toLowerCase().includes(this.businessText.toLowerCase())
      );
    });
    this.businessList = data;
  }
  inviteSend() {
    this.isSubmitted = true;
    if (this.inviteForm.invalid) {
      return;
    }
    this.isSubmitted = false;
    this.pageService.loading.emit(true);
    const body = {
      email: this.formControls.email.value,
      role: this.role,
    };
    this.pageService.sentInvite(body).subscribe(
      (res: any) => {
        if (res) {
          this.isSubmitted = false;
          this.inviteForm.reset();
          this.pageService.loading.emit(false);
        }
      },
      (error) => {
        this.pageService.loading.emit(false);
        this.errorMessage = "Select Role";
      }
    );
  }
  resetPassLink(event: any) {
    const clickedElementId = event.target.id;
    this.userIdData = this.userList.find(
      (x: { id: any }) => x.id == clickedElementId
    );
    const body = {
      email: this.userIdData.email,
    };
    this.pageService.requestToken(body).subscribe((res: any) => {
      if (res) {
      }
    });
  }
  resetPassLinkBusiness(event: any) {
    const clickedElementId = event.target.id;
    this.userIdData = this.businessList.find(
      (x: { id: any }) => x.id == clickedElementId
    );
    const body = {
      email: this.userIdData.email,
    };
    this.pageService.requestToken(body).subscribe((res: any) => {
      if (res) {
      }
    });
  }

  statusManager(event: any) {
    const clickedElementId = event.target.id;
    this.pageService.enableUser(clickedElementId).subscribe((res: any) => {
      if (res) {
        window.location.reload();
        this.managerStatus = res.status;
      }
    });
  }
  statusManagerDisable(event: any) {
    const clickedElementId = event.target.id;
    this.pageService.disableUser(clickedElementId).subscribe((res: any) => {
      if (res) {
        window.location.reload();
      }
    });
  }
  statusBusinessEnable(event: any) {
    const clickedElementId = event.target.id;
    this.pageService
      .EnableDisableBusiness(clickedElementId, true, "")
      .subscribe((res: any) => {
        if (res) {
          window.location.reload();
        }
      });
  }
  statusBusinessDisable(event: any) {
    const clickedElementId = event.target.id;
    this.pageService
      .EnableDisableBusiness(clickedElementId, false, "")
      .subscribe((res: any) => {
        if (res) {
          window.location.reload();
        }
      });
  }
}
