import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { EnterEmailComponent } from "./enter-email/enter-email.component";
import { EnterOtpComponent } from "./enter-otp/enter-otp.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { LoginComponent } from "./login/login.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
  },
  { path: "login", component: LoginComponent },
  { path: "forgot-password", component: ForgotPasswordComponent },
  { path: "enter-email", component: EnterEmailComponent },
  { path: "enter-otp", component: EnterOtpComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
