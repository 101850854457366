<div class="main-content main-content-login">
  <div class="d-flex">
    <div class="login-lt-wrapper">
      <div class="logo-wrapper">
        <img src="../../../assets/img/login-logo.svg" alt="logo" class="logo" />
      </div>
      <p class="logo-text">The Drink App: Managing Portal</p>
    </div>
    <div class="login-rt-wrapper">
      <div class="form-wrapper">
        <h2 class="form-heading">Forgot Password</h2>
        <div class="mt-3">
          <form (ngSubmit)="verifyOTP()">
            <div class="mb-3">
              <label for="emailInput" class="form-label input-label"
                >Enter OTP</label
              >
              <input
                type="text"
                class="form-control input-area"
                formControlName="email"
                id="emailInput"
                placeholder="Please enter OTP"
              />
            </div>
            <div class="d-flex justify-content-between">
              <div class="form-check ms-4"></div>
              <a href="" class="forgot-password">Resend OTP</a>
            </div>

            <button type="submit" class="btn btn-primary login-btn">
              Verify
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
