import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit {
  data: any | undefined;

  constructor() {}

  ngOnInit(): void {
    this.userDetail();
  }

  closeNav() {
    (document.getElementById("sideNavbar") as HTMLElement).style.display =
      "none";
  }

  userDetail() {
    this.data = JSON.parse(localStorage.getItem("user") || "{}");
  }
}
