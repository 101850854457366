<div class="content">
  <div class="row mb-4">
    <div class="cards invite-card me-3">
      <div class="cards-header">
        <h3 class="cards-heading">Mixers and Garnishes</h3>
      </div>
      <div class="invite-content">
        <form
          class="d-flex mob-flex p-4"
          [formGroup]="mixerForm"
          (ngSubmit)="addMixer()"
        >
          <div class="input-group mb-2 mt-4">
            <input
              type="text"
              class="form-control input-area"
              placeholder="Add mixers"
              aria-label="invite"
              formControlName="mixers"
              aria-describedby="basic-addon2"
            />
            <!-- <input
              type="number"
              class="form-control input-area"
              placeholder="$ price"
              aria-label="invite"
              formControlName="price"
              aria-describedby="basic-addon2"
            /> -->
          </div>
          <button type="submit" class="btn invite-btn ms-2 mt-4">Add</button>
        </form>
      </div>
      <div class="list-wrapper row">
        <ng-container *ngFor="let mixer of mixerList">
          <div class="wid-tg-auto p-3">
            <a (click)="deleteMixer($event)" id="{{ mixer?.id }}">
              <img
                src="../../../assets/img/x.svg"
                id="{{ mixer?.id }}"
                alt="back"
                width="22px"
              />
            </a>
            <div class="drinktag guest-tag mx-5">{{ mixer?.name }}</div>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="cards invite-card me-3 mt-5">
      <div class="cards-header">
        <h3 class="cards-heading">Style & Ice</h3>
      </div>
      <div class="invite-content">
        <form
          class="d-flex mob-flex p-4"
          [formGroup]="styleIceForm"
          (ngSubmit)="addStyleIce()"
        >
          <div class="input-group mb-2 mt-4">
            <input
              type="text"
              class="form-control input-area"
              placeholder="Style & Ice"
              aria-label="invite"
              aria-describedby="basic-addon2"
              formControlName="styleice"
            />
          </div>
          <button type="submit" class="btn invite-btn ms-2 mt-4">Add</button>
        </form>
      </div>
      <div class="list-wrapper row">
        <ng-container *ngFor="let styleice of styleIceList">
          <div class="wid-tg-auto p-3">
            <a (click)="deleteStyleIce($event)" id="{{ styleice?.id }}">
              <img
                src="../../../assets/img/x.svg"
                id="{{ styleice?.id }}"
                alt="back"
                width="22px"
              />
            </a>
            <div class="drinktag guest-tag mx-5">{{ styleice?.name }}</div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="cards invite-card me-3 mt-5">
      <div class="cards-header">
        <h3 class="cards-heading">Categories</h3>
      </div>
      <div class="invite-content">
        <form
          class="d-flex mob-flex p-4"
          [formGroup]="categoriesForm"
          (ngSubmit)="addCategories()"
        >
          <div class="input-group mb-2 mt-4">
            <input
              type="text"
              class="form-control input-area"
              placeholder="Categories"
              aria-label="invite"
              aria-describedby="basic-addon2"
              formControlName="categories"
            />
          </div>
          <button type="submit" class="btn invite-btn ms-2 mt-4">Add</button>
        </form>
      </div>
      <div class="manager-list-wrapper row">
        <div class="p-4">
          <table class="center">
            <thead>
              <tr>
                <th>Category</th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let categories of categoriesList">
                <td>{{ categories.name }}</td>
                <td>
                  <button
                    routerLink="/edit-category/{{ categories?.id }}"
                    id="{{ categories?.id }}"
                  >
                    Edit
                  </button>
                </td>
                <td>
                  <button
                    (click)="deleteCategories($event)"
                    id="{{ categories?.id }}"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="cards invite-card me-3 mt-5">
      <div class="cards-header">
        <h3 class="cards-heading">Sub Categories</h3>
      </div>
      <div class="invite-content">
        <form
          class="d-flex mob-flex p-4"
          [formGroup]="subcategoriesForm"
          (ngSubmit)="addSubCategories()"
        >
          <div class="input-group mb-2 mt-4">
            <select
              class="form-select form-control input-area"
              aria-label="Default select example"
              (change)="setSelectedItem($event)"
            >
              <option selected>{{ selectedItem }}</option>

              <ng-container *ngFor="let categories of categoriesList">
                <option [value]="categories.id">{{ categories.name }}</option>
              </ng-container>
            </select>

            <input
              type="text"
              class="form-control input-area"
              placeholder="Sub Categories"
              aria-label="invite"
              aria-describedby="basic-addon2"
              formControlName="subcategories"
            />
          </div>
          <button type="submit" class="btn invite-btn ms-2 mt-4">Add</button>
        </form>
      </div>
      <div class="manager-list-wrapper row">
        <div class="p-4">
          <table class="center">
            <thead>
              <tr>
                <th>Category</th>
                <th>Sub Category</th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let subcategories of subCategoriesList">
                <td>{{ subcategories.category.name }}</td>
                <td>{{ subcategories.name }}</td>
                <td>
                  <button
                    routerLink="/edit-subcategory/{{ subcategories?.id }}"
                    id="{{ subcategories?.id }}"
                  >
                    Edit
                  </button>
                </td>
                <td>
                  <button
                    (click)="deleteSubCategories($event)"
                    id="{{ subcategories?.id }}"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
