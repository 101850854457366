<!-- .........content......... -->
<div class="content">
  <div class="row mb-4">
    <div class="cards invite-card">
      <div
        class="cards-header d-flex justify-content-between align-items-center"
      >
        <div class="d-flex align-items-center">
          <a routerLink="/dashboard">
            <img src="../../../assets/img/left.png" alt="back" width="28px" />
          </a>

          <h3 class="cards-heading mx-3">Change Password</h3>
        </div>
      </div>

      <div class="form-promotion">
        <form [formGroup]="generalForm" (ngSubmit)="changePassword()">
          <div class="search">
            <div class="input-container">
              <input
                [type]="showOldPassword ? 'text' : 'password'"
                class="form-control input-58 input-area"
                formControlName="OldPassword"
                placeholder="Oldpassword"
                aria-label="invite"
                aria-describedby="basic-addon2"
              />
              <button type="button" (click)="toggleOldPasswordVisibility()">
                {{ showOldPassword ? "Hide" : "Show" }}
              </button>
            </div>
          </div>
          <div class="search">
            <div class="input-container">
              <input
                [type]="showNewPassword ? 'text' : 'password'"
                class="form-control input-area input-58"
                formControlName="NewPassword"
                placeholder="New Password"
                aria-label="invite"
                aria-describedby="basic-addon2"
                (input)="checkPasswordMatch()"
              />
              <button type="button" (click)="toggleNewPasswordVisibility()">
                {{ showNewPassword ? "Hide" : "Show" }}
              </button>
            </div>
          </div>

          <div class="search">
            <div class="input-container">
              <input
                [type]="showConfirmPassword ? 'text' : 'password'"
                class="form-control input-area input-58"
                formControlName="ConfirmPassword"
                placeholder="Confirm Password"
                aria-label="invite"
                aria-describedby="basic-addon2"
                (input)="checkPasswordMatch()"
              /><button
                type="button"
                (click)="toggleConfirmPasswordVisibility()"
              >
                {{ showConfirmPassword ? "Hide" : "Show" }}
              </button>
            </div>
            <div *ngIf="!passwordsMatch" class="error-msg">
              Passwords do not match.
            </div>
          </div>

          <button
            type="submit"
            class="btn invite-btn mt-5 btn-170-wid btn-55-height ms-3"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
