import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PageServiceService } from "../page-service.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-create-new",
  templateUrl: "./create-new.component.html",
  styleUrls: ["./create-new.component.css"],
})
export class CreateNewComponent implements OnInit {
  //@ts-ignore
  generalForm: FormGroup;
  isSubmitted: any = false;
  selectedItem: string = "3";
  role: number | undefined;
  errorMessage: string = "";

  constructor(
    public pageService: PageServiceService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.generalForm = this.formBuilder.group({
      promoTitle: ["", Validators.required],
      promoCode: ["", Validators.required],
      discount: ["", Validators.required],
      from_date: [this.getTodaysDate(), Validators.required],
      to_date: [this.getTodaysDate(), Validators.required],
    });
    this.route.queryParams.subscribe((params) => {
      if (params.title) {
        this.generalForm.get("promoTitle")?.setValue(params.title);
      }
    });
  }
  get f() {
    return this.generalForm.controls;
  }

  getTodaysDate() {
    let d = new Date(),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  setSelectedItem(option: string) {
    this.selectedItem = option;
  }
  createPromo() {
    this.isSubmitted = true;
    if (this.generalForm.invalid) {
      return;
    }
    if (this.f.discount.value > 100) {
      return;
    }
    this.pageService.loading.emit(true);
    const body = {
      title: this.f.promoTitle.value,
      promo_code: this.f.promoCode.value,
      discount: this.f.discount.value,
      start_date: this.f.from_date.value,
      end_date: this.f.to_date.value,
      role: +this.selectedItem,
    };

    this.pageService.createPromos(body).subscribe(
      (res: any) => {
        if (res) {
          this.isSubmitted = false;
          this.generalForm.reset();
          this.f.from_date.setValue(this.getTodaysDate());
          this.f.to_date.setValue(this.getTodaysDate());
          window.location.replace("/promotion");
          this.pageService.loading.emit(false);
        }
      },
      (error) => {
        this.pageService.loading.emit(false);
        this.errorMessage = error.error.message;
      }
    );
  }
}
