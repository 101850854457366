<div class="content card">
  <div class="d-flex align-items-center pb-4">
    <a routerLink="/dashboard">
      <img src="../../../assets/img/left.png" alt="back" width="28px" />
    </a>

    <h3 class="cards-heading mx-3">Edit Editor</h3>
  </div>
  <div class="row mb-4">
    <div class="h-10 w-10">
      <img
        src="{{ baseUrl }}{{ editorIdImg?.profile_url }}"
        alt="Users"
        class="real-pic"
      />
    </div>
  </div>
  <div class="cards user-card me-3">
    <form [formGroup]="generalForm" (ngSubmit)="updateEditor()">
      <div class="cards-header border-bottom-0">
        <h3 class="cards-heading">User Name</h3>
      </div>
      <div class="padding">
        <input
          type="text"
          class="form-control input-area"
          placeholder="User name"
          aria-label="invite"
          aria-describedby="basic-addon2"
          formControlName="username"
        />
      </div>
      <div
        *ngIf="isSubmitted && generalForm.get('username')?.hasError('required')"
        class="error-msg"
      >
        Username is required.
      </div>
      <div class="cards-header border-bottom-0">
        <h3 class="cards-heading">First Name</h3>
      </div>
      <div class="padding">
        <input
          type="text"
          class="form-control input-area"
          placeholder="First Name"
          aria-label="invite"
          aria-describedby="basic-addon2"
          formControlName="fname"
        />
      </div>
      <div
        *ngIf="isSubmitted && generalForm.get('fname')?.hasError('required')"
        class="error-msg"
      >
        First name is required.
      </div>
      <div class="cards-header border-bottom-0">
        <h3 class="cards-heading">Last Name</h3>
      </div>
      <div class="padding">
        <input
          type="text"
          class="form-control input-area"
          placeholder="Last Name"
          aria-label="invite"
          aria-describedby="basic-addon2"
          formControlName="lname"
        />
      </div>
      <div
        *ngIf="isSubmitted && generalForm.get('lname')?.hasError('required')"
        class="error-msg"
      >
        Last name is required.
      </div>
      <div class="cards-header border-bottom-0">
        <h3 class="cards-heading">Email</h3>
      </div>
      <div class="padding">
        <input
          type="text"
          class="form-control input-area"
          placeholder="Email"
          aria-label="invite"
          aria-describedby="basic-addon2"
          formControlName="email"
        />
      </div>
      <div class="cards-header border-bottom-0">
        <h3 class="cards-heading">Address</h3>
      </div>
      <div class="padding">
        <input
          type="text"
          class="form-control input-area"
          placeholder="Address"
          aria-label="invite"
          aria-describedby="basic-addon2"
          formControlName="address"
        />
      </div>
      <div class="cards-header border-bottom-0">
        <h3 class="cards-heading">Country Code</h3>
      </div>
      <div class="padding">
        <input
          type="number"
          class="form-control input-area"
          placeholder="Country Code"
          aria-label="invite"
          aria-describedby="basic-addon2"
          formControlName="contrycode"
        />
      </div>
      <div class="cards-header border-bottom-0">
        <h3 class="cards-heading">Mobile Number</h3>
      </div>
      <div class="padding">
        <input
          type="number"
          class="form-control input-area"
          placeholder="mobile number"
          aria-label="invite"
          aria-describedby="basic-addon2"
          formControlName="mobilenumber"
        />
      </div>
      <div class="cards-header border-bottom-0">
        <h3 class="cards-heading">Country</h3>
      </div>
      <div class="padding">
        <input
          type="text"
          class="form-control input-area"
          placeholder="Country"
          aria-label="invite"
          aria-describedby="basic-addon2"
          formControlName="country"
        />
      </div>
      <div class="cards-header border-bottom-0">
        <h3 class="cards-heading">City</h3>
      </div>
      <div class="padding">
        <input
          type="text"
          class="form-control input-area"
          placeholder="City"
          aria-label="invite"
          aria-describedby="basic-addon2"
          formControlName="city"
        />
      </div>
      <div class="cards-header border-bottom-0">
        <h3 class="cards-heading">State</h3>
      </div>
      <div class="padding">
        <input
          type="text"
          class="form-control input-area"
          placeholder="State"
          aria-label="invite"
          aria-describedby="basic-addon2"
          formControlName="state"
        />
      </div>
      <div class="cards-header border-bottom-0">
        <h3 class="cards-heading">Zip Code</h3>
      </div>
      <div class="padding">
        <input
          type="number"
          class="form-control input-area"
          placeholder="Zip Code"
          aria-label="invite"
          aria-describedby="basic-addon2"
          formControlName="zipcode"
        />
      </div>

      <div class="cards-header border-bottom-0">
        <h3 class="cards-heading">Profile Picture</h3>
      </div>
      <div class="padding">
        <input
          name="profilepic"
          type="file"
          class="form-control input-area"
          aria-label="invite"
          aria-describedby="basic-addon2"
          (change)="uploadImage($event)"
        />
      </div>

      <button
        type="submit"
        class="btn invite-btn btn-55-height btn-170-wid mt-5"
      >
        Update
      </button>
    </form>
  </div>
</div>
