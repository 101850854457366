import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { PageServiceService } from "../page-service.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-ticket",
  templateUrl: "./ticket.component.html",
  styleUrls: ["./ticket.component.css"],
})
export class TicketComponent implements OnInit {
  repliesList: any = [];
  replyText: any = "";
  support_id: any = "";
  baseUrl: string = environment.baseUrl;

  constructor(
    public pageService: PageServiceService,
    public activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.params.subscribe((params: any) => {
      this.support_id = params?.support_id;
      this.getqueryReplies(params?.support_id);
    });
  }

  ngOnInit(): void {}
  getqueryReplies(id: any) {
    this.pageService.loading.emit(true);
    this.pageService.getqueryReplies(id).subscribe((res: any) => {
      if (res?.success) {
        this.repliesList = res.data;
        this.pageService.loading.emit(false);
      }
    });
  }
  sendReply() {
    if (this.replyText.trim() === "") {
      return;
    }
    let body = {
      support_id: this.support_id,
      message: this.replyText,
    };
    this.pageService.sendReply(body).subscribe((res: any) => {
      if (res?.success) {
        this.replyText = "";
        this.getqueryReplies(this.support_id);
      }
    });
  }
}
