<!-- .........content......... -->
<div class="content">
  <div class="row mb-4">
    <div class="cards user-card me-3">
      <div
        class="cards-header d-flex justify-content-between align-items-center"
      >
        <h3 class="cards-heading">Guest Newsletter</h3>
        <button
          type="button"
          class="btn btn-wid-8 invite-btn btn-height-35 ms-2"
          routerLink="/guest-newsletter"
        >
          + New
        </button>
      </div>
      <div *ngIf="guestNews.length === 0; else newsList">
        <p class="empty-msg">No data found</p>
      </div>
      <ng-template #newsList>
        <ng-container *ngFor="let guestletter of guestNews">
          <div class="manager-list-wrapper">
            <div class="manager-items justify-content-between">
              <div class="d-flex">
                <div class="manager-icon">
                  <img src="../../../assets/img/bell.svg" alt="view document" />
                </div>

                <div class="mx-3">
                  <p class="manager-name">
                    {{ guestletter?.title }}
                  </p>
                  <p class="emails-bulk mt-2">
                    {{ guestletter?.body }}
                  </p>

                  <div class="d-flex justify-content-between my-4">
                    <p class="profile-message">
                      Published on:
                      <span>{{
                        guestletter?.published_on | date : "MM/dd/YYYY"
                      }}</span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="dropdown">
                <a
                  class="btn"
                  href="javascript:void(0)"
                  role="button"
                  id="dropdownMenuLink"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img src="../../../assets/img/menu.svg" alt="menu" />
                </a>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                  <!-- <li>
                    <a class="dropdown-item dropdown-item-custom" href="#"
                      >Resend</a
                    >
                  </li> -->
                  <!-- <li>
                    <a
                      class="dropdown-item dropdown-item-custom"
                      routerLink="/edit-newsletter/{{ guestletter?.id }}"
                      >Edit</a
                    >
                  </li> -->
                  <li>
                    <a
                      class="dropdown-item dropdown-item-custom"
                      (click)="deleteNewsletter($event)"
                      id="{{ guestletter?.id }}"
                      >Delete</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </ng-container></ng-template
      >
    </div>

    <div class="cards user-card ms-3">
      <div
        class="cards-header d-flex justify-content-between align-items-center"
      >
        <h3 class="cards-heading">Business Newsletter</h3>
        <button
          type="button"
          class="btn btn-wid-8 invite-btn btn-height-35 ms-2"
          routerLink="/business-newsletter"
        >
          + New
        </button>
      </div>
      <div *ngIf="businessNews.length === 0; else busList">
        <p class="empty-msg">No data found</p>
      </div>
      <ng-template #busList>
        <ng-container *ngFor="let businessletter of businessNews">
          <div class="manager-list-wrapper">
            <div class="manager-items justify-content-between">
              <div class="d-flex">
                <div class="manager-icon">
                  <img src="../../../assets/img/bell.svg" alt="view document" />
                </div>
                <div class="mx-3">
                  <p class="manager-name">{{ businessletter?.title }}</p>
                  <p class="emails-bulk mt-2">
                    {{ businessletter?.body }}
                  </p>

                  <div class="d-flex justify-content-between my-4">
                    <p class="profile-message">
                      Published on:
                      <span>{{
                        businessletter?.published_on | date : "MM/dd/YYYY"
                      }}</span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="dropdown">
                <a
                  class="btn"
                  href="javascript:void(0)"
                  role="button"
                  id="dropdownMenuLink"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img src="../../../assets/img/menu.svg" alt="menu" />
                </a>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                  <!-- <li>
                    <a class="dropdown-item dropdown-item-custom" href="#"
                      >Resend</a
                    >
                  </li> -->
                  <!-- <li>
                    <a
                      class="dropdown-item dropdown-item-custom"
                      routerLink="/edit-businessnewsletter/{{
                        businessletter?.id
                      }}"
                      >Edit</a
                    >
                  </li> -->
                  <li>
                    <a
                      class="dropdown-item dropdown-item-custom"
                      (click)="deleteNewsletter($event)"
                      id="{{ businessletter?.id }}"
                      >Delete</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </ng-container></ng-template
      >
    </div>
  </div>
</div>
