<div class="content card">
  <div class="d-flex align-items-center pb-4">
    <a routerLink="/verify-users">
      <img src="../../../assets/img/left.png" alt="back" width="28px" />
    </a>

    <h3 class="cards-heading mx-3">Verify User</h3>
  </div>
  <div class="row mb-4">
    <div class="h-10 w-10">
      <img
        src="{{ baseUrl }}{{ guestIdData?.profile_url }}"
        alt="User profile pic"
        class="real-pic"
      />
    </div>
  </div>

  <div class="cards user-card me-3">
    <div class="cards-header border-bottom-0">
      <h3 class="cards-heading">User Name</h3>
    </div>
    <div class="padding">
      <input
        type="text"
        class="form-control input-area"
        placeholder="User Name"
        aria-label="invite"
        aria-describedby="basic-addon2"
        value="{{ guestIdData?.user_name }}"
        disabled
      />
    </div>
    <div class="cards-header border-bottom-0">
      <h3 class="cards-heading">First Name</h3>
    </div>
    <div class="padding">
      <input
        type="text"
        class="form-control input-area"
        placeholder="First Name"
        aria-label="invite"
        aria-describedby="basic-addon2"
        value="{{ guestIdData?.first_name }}"
        disabled
      />
    </div>
    <div class="cards-header border-bottom-0">
      <h3 class="cards-heading">Last Name</h3>
    </div>
    <div class="padding">
      <input
        type="text"
        class="form-control input-area"
        placeholder="Last Name"
        aria-label="invite"
        aria-describedby="basic-addon2"
        value="{{ guestIdData?.last_name }}"
        disabled
      />
    </div>
    <div class="cards-header border-bottom-0">
      <h3 class="cards-heading">Email</h3>
    </div>
    <div class="padding">
      <input
        type="text"
        class="form-control input-area"
        placeholder="Email"
        aria-label="invite"
        aria-describedby="basic-addon2"
        value="{{ guestIdData?.email }}"
        disabled
      />
    </div>
    <div class="cards-header border-bottom-0">
      <h3 class="cards-heading">Address</h3>
    </div>
    <div class="padding">
      <input
        type="text"
        class="form-control input-area"
        placeholder="Address"
        aria-label="invite"
        aria-describedby="basic-addon2"
        value="{{ guestIdData?.address1 }}"
        disabled
      />
    </div>
    <div class="row mb-4 p-3">
      <h3 class="cards-heading">License Certificate</h3>
      <div class="h-10 w-10">
        <img
          src="{{ baseUrl }}{{ guestIdData?.doc_pic_front }}"
          alt="License certificate"
          class="real-pic"
          (click)="showEnlargedView(guestIdData?.doc_pic_front)"
        />
      </div>
      <div class="h-10 w-10">
        <img
          src="{{ baseUrl }}{{ guestIdData?.doc_pic_back }}"
          alt="License certificate"
          class="real-pic"
          (click)="showEnlargedView(guestIdData?.doc_pic_back)"
        />
      </div>
      <div
        class="enlarged-view"
        [ngStyle]="enlargedViewStyle"
        *ngIf="showEnlarged"
      >
        <span class="close-icon" (click)="closeEnlargedView()">&times;</span>
        <img
          class="enlargeimg"
          src="{{ baseUrl }}{{ enlargedImageUrl }}"
          alt="Enlarged Image"
        />
      </div>
    </div>

    <div class="pb-5 px-3">
      <button
        (click)="verifyUser($event)"
        id="{{ guestIdData?.id }}"
        class="btn invite-btn btn-55-height btn-170-wid mt-5"
        *ngIf="!showSubmitButton"
      >
        Verify
      </button>
      <button
        id="{{ guestIdData?.id }}"
        class="btn danger-btn btn-55-height btn-170-wid mt-5 mx-3"
        (click)="toggleInput($event)"
        *ngIf="!showSubmitButton"
      >
        Reject
      </button>
    </div>
    <form [formGroup]="userRejectForm" (ngSubmit)="userReject()">
      <div class="p-3">
        <input
          *ngIf="showInput"
          type="text"
          class="form-control input-area"
          aria-label="invite"
          aria-describedby="basic-addon2"
          placeholder="Enter reason for reject"
          formControlName="reajectReason"
        />
        <div
          *ngIf="
            userRejectForm.controls.reajectReason.invalid &&
            (userRejectForm.controls.reajectReason.dirty ||
              userRejectForm.controls.reajectReason.touched)
          "
          class="text-danger"
        >
          Reason is required
        </div>
        <button
          *ngIf="showInput"
          class="btn danger-btn btn-55-height btn-170-wid mt-5 mx-3"
          type="submit"
        >
          Submit
        </button>
      </div>
    </form>
  </div>
</div>
