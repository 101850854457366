<!-- .........content......... -->
<div class="content">
  <div class="row mb-4">
    <div class="cards invite-card">
      <div
        class="cards-header d-flex justify-content-between align-items-center"
      >
        <div class="d-flex align-items-center">
          <h3 class="cards-heading">Guest Payments</h3>

          <div *ngIf="errorMessage" class="alert alert-danger">
            {{ errorMessage }}
          </div>
          <div class="searchs">
            <input
              type="text"
              class="form-control input-area align-placeholder"
              placeholder="search by business name"
              aria-label="invite"
              aria-describedby="basic-addon2"
              (input)="searchUsers()"
              [(ngModel)]="userText"
            />
          </div>
        </div>
        <div class="dropdown">
          <a
            class="btn"
            href="javascript:void(0)"
            role="button"
            id="dropdownMenuLink"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <button class="btn invite-btn ms-2">Download Data</button>
          </a>

          <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
            <li>
              <a
                class="dropdown-item dropdown-item-custom"
                (click)="downloadCustomData('all')"
                >All History</a
              >
            </li>
            <li>
              <a
                class="dropdown-item dropdown-item-custom"
                (click)="downloadCustomData('today')"
                >Today History</a
              >
            </li>
            <li>
              <a
                class="dropdown-item dropdown-item-custom"
                (click)="downloadCustomData('yesterday')"
                >Yesterday History</a
              >
            </li>
            <li>
              <a
                class="dropdown-item dropdown-item-custom"
                (click)="downloadCustomData('past7days')"
                >Past 7 days History</a
              >
            </li>
          </ul>
        </div>
      </div>

      <div class="manager-list-wrapper">
        <ng-container *ngFor="let guest of guestPayment">
          <div class="guest-row mobile-hide">
            <div
              class="currency me-4 d-flex align-items-center justify-content-center mb-2"
            >
              <img src="../../../assets/img/dollar.svg" alt="profile pic" />
            </div>
            <div class="payment-width">
              <h1 class="profile-name my-1">
                {{ guest.user?.first_name + " " + guest.user?.last_name }}
              </h1>
              <p class="profile-">{{ guest.user?.email }}</p>
              <p class="profile">Order Id # {{ guest?.order_id }}</p>
              <p class="profile">Business: {{ guest?.order.business?.name }}</p>
            </div>
            <div class="wid-tg-15">
              <div class="tag guest-tag mx-5">
                <!-- {{ guest?.user_role == 3 ? "Guest" : "Business" }} -->
                Guest
              </div>
            </div>
            <div
              [ngClass]="
                guest?.refunds && guest.refunds.length > 0
                  ? 'status status-red mx-5'
                  : 'status status-green mx-5'
              "
            >
              {{
                guest?.refunds && guest.refunds.length > 0
                  ? guest?.refunds[0].metadata?.refund_type
                  : "Received"
              }}
            </div>
            <div
              class="d-flex align-items-center justify-content-center mx-5 w-25"
            >
              <p class="date">{{ guest?.doc | date : "M/d/yy" }}</p>
              <p class="dot ms-2"></p>
              <span class="date ms-2">{{ guest?.doc | date : "h:mm a" }}</span>
            </div>

            <h2
              [ngClass]="
                guest?.refunds && guest.refunds.length > 0
                  ? 'status status-red mx-5'
                  : 'status status-green mx-5'
              "
            >
              $

              {{ guest?.refunds && guest.refunds.length > 0 ? " -" : "+" }}
              {{ guest?.amount }}
            </h2>

            <a
              routerLink="/view-payment/{{ guest?.id }}"
              style="text-decoration: none"
            >
              <div
                class="currency me-4 d-flex align-items-center justify-content-center mb-2"
              >
                <img src="../../../assets/img/eye1.svg" alt="eye icon" />
              </div>
            </a>
          </div>

          <!--.............. mobile design..........  -->
          <div class="px-5 py-3 border-bottom mobile-block">
            <div class="d-flex align-items-center mb-4">
              <div
                class="d-flex align-items-center justify-content-center mx-5 w-25"
              >
                <p class="date">{{ guest?.doc | date : "M/d/yy" }}</p>
                <p class="dot ms-2"></p>
                <span class="date ms-2">{{
                  guest?.doc | date : "h:mm a"
                }}</span>
              </div>
              <p class="dot ms-2"></p>
              <div
                [ngClass]="
                  guest?.refunds && guest.refunds.length > 0
                    ? 'status status-red mx-5'
                    : 'status status-green mx-5'
                "
              >
                {{
                  guest?.refunds && guest.refunds.length > 0
                    ? guest?.refunds[0].metadata?.refund_type
                    : "Received"
                }}
              </div>
            </div>
            <div class="d-flex">
              <div
                class="currency me-4 d-flex align-items-center justify-content-center mb-2"
              >
                <img src="../../../assets/img/dollar.svg" alt="profile pic" />
              </div>
              <div class="payment-width">
                <h1 class="profile-name my-1">
                  {{ guest.user?.first_name + " " + guest.user?.last_name }}
                </h1>
                <p class="profile-">{{ guest.user?.email }}</p>
                <p class="profile">Order Id # {{ guest?.order_id }}</p>
                <p class="profile">
                  Business: {{ guest?.order.business?.name }}
                </p>
              </div>
              <h2
                [ngClass]="
                  guest?.refunds && guest.refunds.length > 0
                    ? 'status status-red mx-5'
                    : 'status status-green mx-5'
                "
              >
                $

                {{ guest?.refunds && guest.refunds.length > 0 ? " -" : "+" }}
                <!-- {{ guest.order?.final_price }} -->
                {{ guest.amount }}
              </h2>
            </div>
            <div class="wid-tg-15">
              <div class="tag guest-tag mx-5">Guest</div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <ngb-pagination
    [(page)]="currentPage"
    [pageSize]="20"
    [collectionSize]="totalItems"
    (pageChange)="pageChange($event)"
    [directionLinks]="false"
  ></ngb-pagination>
  <!-- Bussiness Payment -->
  <!-- <div class="row mb-4">
    <div class="cards invite-card">
      <div
        class="cards-header d-flex justify-content-between align-items-center"
      >
        <h3 class="cards-heading">Business Payments</h3>
      </div>
      <div class="manager-list-wrapper">
        <div class="guest-row mobile-hide">
          <div
            class="currency me-4 d-flex align-items-center justify-content-center mb-2"
          >
            <img src="../../../assets/img/dollar.svg" alt="profile pic" />
          </div>
          <div class="payment-width">
            <h1 class="profile-name my-1">Business Name</h1>
            <p class="profile-">johndoe@gmail.com</p>
          </div>
          <div class="wid-tg-15">
            <div class="tag guest-tag mx-5">Business</div>
          </div>
          <div class="status status-close mx-5">Received</div>
          <div
            class="d-flex align-items-center justify-content-center mx-5 w-25"
          >
            <p class="date">03/04/2021</p>
            <p class="dot ms-2"></p>
            <span class="date ms-2">11:50 AM</span>
          </div>
          <h2 class="amount">$44.20</h2>
          <a href="/view-payment" style="text-decoration: none">
            <div
              class="currency me-4 d-flex align-items-center justify-content-center mb-2"
            >
              <img src="../../../assets/img/eye1.svg" alt="profile pic" />
            </div>
          </a>
        </div>

       .............. mobile design..........
        <div class="px-5 py-3 border-bottom mobile-block">
          <div class="d-flex align-items-center mb-4">
            <div
              class="d-flex align-items-center justify-content-center mx-5 w-25"
            >
              <p class="date">03/04/2021</p>
              <p class="dot ms-2"></p>
              <span class="date ms-2">11:50 AM</span>
            </div>
            <p class="dot ms-2"></p>
            <div class="status status-close mx-5">Received</div>
          </div>
          <div class="d-flex">
            <div
              class="currency me-4 d-flex align-items-center justify-content-center mb-2"
            >
              <img src="../../../assets/img/dollar.svg" alt="profile pic" />
            </div>
            <div class="payment-width">
              <h1 class="profile-name my-1">Guest Name</h1>
              <p class="profile-">johndoe@gmail.com</p>
            </div>
            <h2 class="amount">$44.20</h2>
          </div>
          <div class="wid-tg-15">
            <div class="tag guest-tag mx-5">Business</div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
</div>
