<!-- .........content......... -->
<div class="content">
  <div class="row mb-4">
    <div class="cards invite-card me-3">
      <div class="cards-header">
        <h3 class="cards-heading">Invite</h3>
      </div>
      <div class="invite-content">
        <form
          class="d-flex mob-flex"
          [formGroup]="inviteForm"
          (ngSubmit)="inviteSend()"
        >
          <div class="input-group mb-2">
            <input
              type="text"
              class="form-control input-area"
              placeholder="mail@abc"
              aria-label="invite"
              aria-describedby="basic-addon2"
              formControlName="email"
            />
            <button
              class="btn dropdown-btn dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {{ selectedItem }}
            </button>
            <ul class="dropdown-menu dropdown-menu-end">
              <li>
                <a
                  class="dropdown-item dropdown-item-custom"
                  (click)="setSelectedItem('Editor')"
                  >Editor</a
                >
              </li>
              <li>
                <a
                  class="dropdown-item dropdown-item-custom"
                  (click)="setSelectedItem('Manager')"
                  >Manager</a
                >
              </li>
            </ul>
          </div>
          <button type="submit" class="btn invite-btn ms-2">Invite now</button>
        </form>
        <div
          *ngIf="isSubmitted && formControls.email.errors"
          class="form-error-block"
        >
          <div *ngIf="formControls.email.errors.required" class="error-msg">
            Email is required
          </div>
          <div *ngIf="formControls.email.errors.email" class="error-msg">
            Email is invalid!
          </div>
        </div>
        <p class="invit-msg">
          An invitation will be sent to the email address.
        </p>
        <p class="status-open">{{ errorMessage }}</p>
      </div>
    </div>

    <div class="cards collection-card ms-3">
      <a href="/payment" style="text-decoration: none">
        <div class="cards-header">
          <h3 class="cards-heading">
            Today's collection so far
            <span style="color: rgb(64, 64, 155); font-size: 18px">
              (View)
            </span>
          </h3>
        </div>
      </a>
      <div class="collection">
        <div>
          <p class="collection-heading">Revenue</p>
          <span class="revenue">${{ revenue?.totalRevenue }}</span>
        </div>
        <hr class="collection-divider" />
        <div>
          <p class="collection-heading">Orders served</p>
          <span class="revenue">{{ revenue?.count }}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="row mb-4 mobile-card">
    <div class="cards manager-card me-3">
      <div class="cards-header">
        <h3 class="cards-heading">Managers</h3>
      </div>
      <div class="manager-list-wrapper">
        <ng-container *ngFor="let user of managerList">
          <div
            class="manager-items justify-content-between"
            [ngClass]="{ 'expired-row': user.status == 0 }"
          >
            <div class="d-flex">
              <div class="manager-icon">
                <ng-container
                  *ngIf="
                    user.profile_url && user.profile_url !== '';
                    else elseTemp1
                  "
                >
                  <img
                    src="{{ baseUrl }}{{ user.profile_url }}"
                    alt="managers"
                    class="real-pic"
                  />
                </ng-container>
                <ng-template #elseTemp1>
                  <img src="../../../assets/img/indiviual.svg" alt="managers" />
                </ng-template>
              </div>
              <div class="ms-3">
                <p class="manager-name">
                  {{ user?.first_name ? user?.first_name : "N/A" }}
                  {{ user?.last_name ? user?.last_name : "N/A" }}
                </p>

                <p class="manager-email">
                  {{ user?.email ? user?.email : "N/A" }}
                </p>
              </div>
            </div>
            <div class="dropdown">
              <a
                class="btn"
                href="javascript:void(0)"
                role="button"
                id="dropdownMenuLink"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img src="../../../assets/img/menu.svg" alt="menu" />
              </a>

              <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <li>
                  <a
                    class="dropdown-item dropdown-item-custom"
                    routerLink="/view-manager/{{ user.id }}"
                    >View</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item dropdown-item-custom"
                    routerLink="/edit-manager/{{ user.id }}"
                    >Edit</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item dropdown-item-custom"
                    (click)="resetPassLinkManager($event)"
                    id="{{ user?.id }}"
                    >Password Reset Link</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item dropdown-item-custom"
                    (click)="
                      user?.status
                        ? statusManagerDisable($event)
                        : statusManager($event)
                    "
                    id="{{ user?.id }}"
                    >{{ user.status === 1 ? "Disable" : "Enable" }}</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="cards editors-card ms-3">
      <div class="cards-header">
        <h3 class="cards-heading">Editors</h3>
      </div>
      <div class="manager-list-wrapper">
        <ng-container *ngFor="let user of editorList">
          <div
            class="manager-items justify-content-between"
            [ngClass]="{ 'expired-row': user.status == 0 }"
          >
            <div class="d-flex">
              <div class="manager-icon">
                <ng-container
                  *ngIf="
                    user.profile_url && user.profile_url !== '';
                    else elseTemp1
                  "
                >
                  <img
                    src="{{ baseUrl }}{{ user?.profile_url }}"
                    alt="managers"
                    class="real-pic"
                  />
                </ng-container>
                <ng-template #elseTemp1>
                  <img src="../../../assets/img/indiviual.svg" alt="managers" />
                </ng-template>
              </div>
              <div class="ms-3">
                <p class="manager-name">
                  {{ user?.first_name ? user?.first_name : "N/A" }}
                  {{ user?.last_name ? user?.last_name : "N/A" }}
                </p>

                <p class="manager-email">
                  {{ user?.email ? user?.email : "N/A" }}
                </p>
              </div>
            </div>
            <div class="dropdown">
              <a
                class="btn"
                href="javascript:void(0)"
                role="button"
                id="dropdownMenuLink"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img src="../../../assets/img/menu.svg" alt="menu" />
              </a>

              <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <li>
                  <a
                    class="dropdown-item dropdown-item-custom"
                    routerLink="/view-editors/{{ user.id }}"
                    >View</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item dropdown-item-custom"
                    routerLink="/edit-editors/{{ user.id }}"
                    >Edit</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item dropdown-item-custom"
                    (click)="resetPassLinkEditor($event)"
                    id="{{ user?.id }}"
                    >Password Reset Link</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item dropdown-item-custom"
                    (click)="
                      user?.status
                        ? statusManagerDisable($event)
                        : statusManager($event)
                    "
                    id="{{ user?.id }}"
                    >{{ user.status === 1 ? "Disable" : "Enable" }}</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
